import type { FC } from 'react';
import React, { useMemo, useRef, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonBase,
  Dialog,
  Divider,
  IconButton,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import { FormattedMessage } from 'react-intl';
import { LogOut } from 'react-feather';
import AuthSteps from 'src/views/auth/AuthSteps';
import { AuthStep } from 'src/constants/states';
import { AdminRole, getInitials, getPublicName, isEmployer } from 'src/types/user';
import { AccountCircleOutlined } from '@material-ui/icons';
import { Theme } from 'src/theme';
import { Role } from '../../../types/employment';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Ubuntu',
  },
  shopAvatar: {
    borderRadius: 8,
    marginLeft: 'auto',
  },
  popover: {
    backgroundColor: theme.palette.background.default,
    width: 230,
    marginTop: 5,
    borderRadius: 8,
    border: '1px solid #CBCBCB',
    boxShadow: 'none',
  },
  menuItem: {
    paddingLeft: 24,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  iconButton: {
    width: 25,
    height: 25,
  },
  customBadge: {
    backgroundColor: 'red',
  },
}));

const AvatarTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    border: '1px solid #CBCBCB',
    fontSize: 12,
  },
}))(Tooltip);

const Account: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const ref = useRef<any>(null);
  const { user, isAuthenticated, isAnonymous, signOut } = useAuth();
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);

  const isUserMemberOfSalesTeam = useMemo(() => user?.adminRole === AdminRole.SALES, [user]);

  const openMenu = (): void => {
    setMenuOpen(true);
  };

  const closeMenu = (): void => {
    setMenuOpen(false);
  };

  const openDialog = (): void => {
    setDialogOpen(true);
  };

  const closeDialog = (): void => {
    setDialogOpen(false);
  };

  const handleSignOut = async (): Promise<void> => {
    if (isDialogOpen) {
      closeDialog();
    }
    closeMenu();
    await signOut();
    history.push('/');
  };

  return (
    <>
      {isAuthenticated && !isAnonymous ? (
        <Box>
          <Box
            display="flex"
            alignItems="center"
            component={ButtonBase}
            onClick={openMenu}
            // @ts-ignore
            ref={ref}
          >
            <AvatarTooltip title={user.isAnonymous ? 'anonymous' : getPublicName(user)} placement="bottom-end">
              <Badge variant="dot" classes={{ badge: classes.customBadge }} invisible={!isUserMemberOfSalesTeam}>
                <Avatar
                  className={classes.avatar}
                  alt="Avatar"
                  src={user.avatar ?? ''}
                  style={user.avatar ? {} : { border: '2px solid #363636' }}
                >
                  {user.isAnonymous ? '?' : user.avatar ? user.avatar : getInitials(user)}
                </Avatar>
              </Badge>
            </AvatarTooltip>
          </Box>
          <Menu
            onClose={closeMenu}
            onClick={closeMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            PaperProps={{ className: classes.popover }}
            getContentAnchorEl={null}
            anchorEl={ref.current}
            open={isMenuOpen}
          >
            <MenuItem className={classes.menuItem} component={RouterLink} to="/dashboard">
              <Typography color="primary" variant="caption">
                <FormattedMessage id="menu.profile" />
              </Typography>
            </MenuItem>
            {isEmployer(user) && <Divider variant="middle" style={{ marginTop: 10, marginBottom: 10 }} /> &&
              Array.from(user.shopIdsWithShopInfoAndRole)
                .filter(([, infoAndRole]) => infoAndRole.role === Role.EMPLOYER)
                .map(([eachShopId, infoAndRole]) => {
                  return (
                    <MenuItem className={classes.menuItem} component={RouterLink} to={`/my-place/${infoAndRole.url}`} key={eachShopId}>
                      <Typography color="primary" variant="caption">
                        {infoAndRole.name}
                      </Typography>
                      <Avatar alt="Place" variant="square" className={classes.shopAvatar} src={infoAndRole.avatar} />
                    </MenuItem>
                  );
                })}
            <Divider variant="middle" style={{ marginTop: 10, marginBottom: 10 }} />
            <MenuItem onClick={handleSignOut}>
              <ListItemIcon style={{ minWidth: 35 }}>
                <LogOut fontSize="small" />
              </ListItemIcon>
              <Typography color="primary" variant="caption">
                <FormattedMessage id="menu.signout" />
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      ) : (
        <Box>
          {isMobile ? (
            <IconButton color="primary" onClick={openDialog} className={classes.iconButton}>
              <AccountCircleOutlined />
            </IconButton>
          ) : (
            <Button variant="outlined" color="primary" size="small" onClick={openDialog}>
              <Typography color="inherit" variant="button">
                <FormattedMessage id="menu.signin.signup" />
              </Typography>
            </Button>
          )}
        </Box>
      )}
      <Dialog open={isDialogOpen} onClose={closeDialog} fullScreen={isMobile}>
        <AuthSteps authStep={AuthStep.SIGNIN} onCloseDialog={closeDialog} />
      </Dialog>
    </>
  );
};

export default Account;
