import type { FC } from 'react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Card, CardContent, Container, makeStyles, Typography } from '@material-ui/core';
import type { Theme } from 'src/theme';
import { Error } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: '100%',
  },
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 80,
  },
  card: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
  },
  cardContent: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    minHeight: 100,
  },
  icon: {
    color: theme.palette.error.main,
    width: 55,
    height: 55,
  },
}));

const ErrorView: FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container className={classes.cardContainer} maxWidth="md">
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Box textAlign="center">
              <Error className={classes.icon} />
            </Box>
            <Box textAlign="center" mt={2}>
              <div>
                <Typography color="primary" variant="h5" gutterBottom>
                  Oops...
                </Typography>
                <Typography color="secondary" variant="h5" gutterBottom>
                  Something went wrong
                </Typography>
              </div>
            </Box>
            <Box mt={2} textAlign="center">
              <Button variant="contained" color="primary" component={RouterLink} to="/">
                Go Back
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default ErrorView;
