import type { FC } from 'react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, CircularProgress, Link, makeStyles, TextField, Typography } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { SnackbarAction, useSnackbar, VariantType } from 'notistack';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import useAuth from 'src/hooks/useAuth';
import { AuthContextValue } from 'src/contexts/FirebaseAuthContext';
import { hasFirebaseAuthError, logError } from 'src/lib/utils';
import { AuthStep } from 'src/constants/states';
import { Theme } from 'src/theme';

interface ForgotPasswordFormProps {
  handleStepChange?: (authStep: AuthStep, email?: string) => void;
  handleForgotPasswordStep?: (authStep: AuthStep, email?: string) => void;
  onGoBack?: () => void;
  emailInput?: string | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    position: 'relative',
    textDecoration: 'none',
    cursor: 'pointer',
    fontFamily: 'Ubuntu',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.25,
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
    '&::after': {
      content: `''`,
      position: 'absolute',
      width: '100%',
      height: 2,
      top: 22,
      bottom: 0,
      left: 0,
      backgroundColor: theme.palette.secondary.main,
      visibility: 'visible',
      transition: '0.5s ease all .3s',
      [theme.breakpoints.down('sm')]: {
        transition: 'none',
      },
    },
    '&:hover::after': {
      width: 0,
      visibility: 'hidden',
      transition: '0.3s ease all',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        visibility: 'visible',
        transition: 'none',
      },
    },
  },
}));

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({ handleStepChange, handleForgotPasswordStep, onGoBack, emailInput }) => {
  const classes = useStyles();
  const { sendPasswordResetEmail } = useAuth() as AuthContextValue;
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const history = useHistory();
  const intl = useIntl();

  const handleSubmit = (email: string): void => {
    if (handleStepChange) {
      handleStepChange(AuthStep.FORGOT_PASSWORD_SUCCESS, email);
    } else if (handleForgotPasswordStep) {
      handleForgotPasswordStep(AuthStep.FORGOT_PASSWORD_SUCCESS, email);
    }
  };

  const handleGoBackClick = (): void => {
    if (handleStepChange) {
      handleStepChange(AuthStep.SIGNIN);
    } else if (onGoBack) {
      onGoBack();
    }
  };

  return (
    <>
      <Box textAlign="center">
        <Typography color="textPrimary" variant="h3">
          <FormattedMessage id="forgot.password.title" />
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography color="primary" variant="subtitle1">
          <FormattedMessage id="forgot.password.headline" />
        </Typography>
      </Box>
      <Box mt={1}>
        <Formik
          initialValues={{
            email: emailInput ?? '',
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .nullable()
              .email(intl.formatMessage({ id: 'forgot.password.email.valid' }))
              .required(intl.formatMessage({ id: 'forgot.password.email.required' })),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              await sendPasswordResetEmail(values.email);
              if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
                handleSubmit(values.email);
              }
            } catch (err) {
              logError(err);
              let errorMessage = intl.formatMessage({ id: 'forgot.password.error' });
              let variantType = 'error' as VariantType;
              let action = null as SnackbarAction;
              if (hasFirebaseAuthError(err, 'auth/user-not-found')) {
                errorMessage = intl.formatMessage({ id: 'forgot.password.email.exists' });
                variantType = 'warning';
                action = (
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={() => {
                      if (handleStepChange) {
                        handleStepChange(AuthStep.SIGNUP, values.email);
                      } else {
                        history.push(`/signup?email=${values.email}`);
                      }
                    }}
                  >
                    <Typography color="textSecondary" variant="button">
                      <FormattedMessage id="auth.signup" />
                    </Typography>
                  </Button>
                );
              }
              setStatus({ success: false });
              setErrors({ submit: errorMessage });
              setSubmitting(false);
              enqueueSnackbar(errorMessage, {
                variant: variantType,
                action,
              });
            }
          }}
        >
          {({ errors, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Box>
                <TextField
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  label={intl.formatMessage({ id: 'forgot.password.email' })}
                  name="email"
                  type="email"
                  onChange={handleChange}
                  value={values.email}
                  variant="outlined"
                  color="secondary"
                  margin="normal"
                />
              </Box>
              <Box mt={3}>
                <Button variant="contained" color="primary" fullWidth type="submit" size="large" disabled={Boolean(isSubmitting)}>
                  <FormattedMessage id="forgot.password.cta" />
                  {isSubmitting && <CircularProgress size={25} />}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
      <Box mt={3} ml="3px">
        <Link onClick={handleGoBackClick} className={classes.link}>
          <FormattedMessage id="forgot.password.back" />
        </Link>
      </Box>
    </>
  );
};

ForgotPasswordForm.propTypes = {
  handleStepChange: PropTypes.func,
  handleForgotPasswordStep: PropTypes.func,
  onGoBack: PropTypes.func,
  emailInput: PropTypes.string,
};

export default ForgotPasswordForm;
