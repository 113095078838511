import { lazy } from 'react';
import { getFromStorage, setToStorage } from 'src/lib/appStorage';

const PAGE_FORCE_REFRESHED = 'page_force_refreshed';

// https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
const componentLoader = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = getFromStorage(PAGE_FORCE_REFRESHED) || false;

    try {
      const component = await componentImport();
      setToStorage(PAGE_FORCE_REFRESHED, false);

      return component;
    } catch (err) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        setToStorage(PAGE_FORCE_REFRESHED, true);
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw err;
    }
  });

export default componentLoader;
