import type { FC } from 'react';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import type { Theme } from 'src/theme';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import GoogleIcon from 'src/assets/img/google.svg';
import FacebookIcon from 'src/assets/img/facebook.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { AuthContextValue } from 'src/contexts/FirebaseAuthContext';
import { Close, Visibility, VisibilityOff } from '@material-ui/icons';
import { AuthStep } from 'src/constants/states';
import { triggerSignupEmail } from 'src/lib/userService';
import useLocale from 'src/hooks/useLocale';
import { logError } from 'src/lib/utils';
import ForgotPasswordForm from './ForgotPasswordForm';
import ForgotPasswordSuccess from './ForgotPasswordSuccess';

interface SignInFormProps {
  handleStepChange?: (authStep: AuthStep) => void;
  emailInput?: string | null;
  openForgotPassword?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  providerButton: {
    boxShadow: 'none',
    border: '2px solid #CBCBCB',
    borderRadius: 8,
    backgroundColor: theme.palette.common.white,
  },
  providerIcon: {
    width: 16,
    height: 16,
    position: 'absolute',
    left: 15,
  },
  divider: {
    flexGrow: 1,
    border: '1px solid #CBCBCB',
  },
  dividerText: {
    margin: theme.spacing(2),
    color: theme.palette.secondary.dark,
  },
  link: {
    position: 'relative',
    textDecoration: 'none',
    cursor: 'pointer',
    fontFamily: 'Ubuntu',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.25,
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
    '&::after': {
      content: `''`,
      position: 'absolute',
      width: '100%',
      height: '1.2px',
      top: 18,
      bottom: 0,
      left: 0,
      backgroundColor: theme.palette.secondary.main,
      visibility: 'visible',
      transition: '0.5s ease all .3s',
      [theme.breakpoints.down('sm')]: {
        transition: 'none',
      },
    },
    '&:hover::after': {
      width: 0,
      visibility: 'hidden',
      transition: '0.3s ease all',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        visibility: 'visible',
        transition: 'none',
      },
    },
  },
  container: {
    height: '150%',
    maxWidth: 530,
    paddingBottom: theme.spacing(6),
    backgroundColor: theme.palette.background.default,
  },
  closeIcon: {
    width: '55px',
    height: '55px',
    top: '15px',
    right: '4px',
    position: 'absolute',
    cursor: 'pointer',
  },
}));

const SignInForm: FC<SignInFormProps> = ({ handleStepChange, emailInput, openForgotPassword }) => {
  const classes = useStyles();
  const { signInWithEmailAndPassword, signInWithGoogle, signInWithFacebook } = useAuth() as AuthContextValue;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMountedRef = useIsMountedRef();
  const { locale } = useLocale();
  const intl = useIntl();
  const [isForgotPasswordOpen, setForgotPasswordOpen] = useState<boolean>(openForgotPassword ?? false);
  const [forgotPasswordStep, setForgotPasswordStep] = useState<AuthStep | null>(isForgotPasswordOpen ? AuthStep.FORGOT_PASSWORD : null);
  const [forgotPasswordEmailInput, setForgotPasswordEmailInput] = useState<string | null | undefined>(null);

  const handleGoogleClick: () => Promise<void> = async () => {
    return signInWithGoogle().then((newUserAndEmail) => {
      const { isNewUser, email } = newUserAndEmail;
      return handleProviderClick(isNewUser, email);
    });
  };

  const handleFacebookClick = async () => {
    return signInWithFacebook().then((newUserAndEmail) => {
      const { isNewUser, email } = newUserAndEmail;
      return handleProviderClick(isNewUser, email);
    });
  };

  const handleProviderClick = async (isNewUser: boolean, email: string) => {
    if (handleStepChange) {
      if (isNewUser) {
        handleStepChange(AuthStep.WELCOME);
      } else {
        handleStepChange(AuthStep.FINISH);
      }
    }
    if (isNewUser) {
      await triggerSignupEmail(locale, email);
    }
  };

  const handleSigninSuccess = (): void => {
    if (handleStepChange) {
      handleStepChange(AuthStep.FINISH);
    }
  };

  const handleForgotPasswordClick = (): void => {
    if (handleStepChange) {
      handleStepChange(AuthStep.FORGOT_PASSWORD);
    } else {
      openForgotPasswordDialog();
      setForgotPasswordStep(AuthStep.FORGOT_PASSWORD);
    }
  };

  const openForgotPasswordDialog = (): void => {
    setForgotPasswordOpen(true);
  };

  const closeForgotPasswordDialog = (): void => {
    setForgotPasswordOpen(false);
  };

  const handleForgotPasswordStep = (step: AuthStep, email?: string): void => {
    setForgotPasswordStep(step);
    setForgotPasswordEmailInput(email);
  };

  return (
    <>
      <Box>
        <Button
          className={classes.providerButton}
          fullWidth
          size="large"
          /* eslint-disable-next-line no-console */
          onClick={() => handleGoogleClick().catch((reason) => console.log(`Google log-in error ${reason}`))}
        >
          <img className={classes.providerIcon} alt="Google" src={GoogleIcon} />
          <Typography color="primary" variant="button">
            <FormattedMessage id="auth.signon.google" />
          </Typography>
        </Button>
      </Box>
      <Box mt={2}>
        <Button
          className={classes.providerButton}
          fullWidth
          size="large"
          /* eslint-disable-next-line no-console */
          onClick={() => handleFacebookClick().catch((reason) => console.log(`Facebook log-in error ${reason}`))}
        >
          <img className={classes.providerIcon} alt="Facebook" src={FacebookIcon} />
          <Typography color="primary" variant="button">
            <FormattedMessage id="auth.signon.facebook" />
          </Typography>
        </Button>
      </Box>
      <Box mt={2} display="flex" alignItems="center">
        <Divider className={classes.divider} orientation="horizontal" />
        <Typography variant="caption" className={classes.dividerText}>
          <FormattedMessage id="auth.signon.email" />
        </Typography>
        <Divider className={classes.divider} orientation="horizontal" />
      </Box>
      <Formik
        initialValues={{
          email: emailInput ?? '',
          password: '',
          showPassword: false,
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .nullable()
            .email(intl.formatMessage({ id: 'auth.form.email.valid' }))
            .required(intl.formatMessage({ id: 'auth.form.email.required' })),
          password: Yup.string()
            .nullable()
            .required(intl.formatMessage({ id: 'auth.form.password.required' })),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await signInWithEmailAndPassword(values.email, values.password);
            if (isMountedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
              handleSigninSuccess();
            }
          } catch (err) {
            logError(err);
            const errorMessage = intl.formatMessage({ id: 'auth.signin.error' });
            if (isMountedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: errorMessage });
              setSubmitting(false);
              enqueueSnackbar(errorMessage, {
                variant: 'error',
              });
            }
          }
        }}
      >
        {({ errors, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              fullWidth
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              label={intl.formatMessage({ id: 'auth.form.email' })}
              name="email"
              type="email"
              onChange={handleChange}
              value={values.email}
              variant="outlined"
              color="secondary"
              margin="normal"
            />
            <TextField
              fullWidth
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              label={intl.formatMessage({ id: 'auth.form.password' })}
              name="password"
              type={values.showPassword ? 'text' : 'password'}
              onChange={handleChange}
              value={values.password}
              variant="outlined"
              color="secondary"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setFieldValue('showPassword', !values.showPassword)}>
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box ml="3px" minHeight="45px">
              <Link onClick={handleForgotPasswordClick} className={classes.link}>
                <FormattedMessage id="auth.signin.forgot.password" />
              </Link>
            </Box>
            <Box mt={2} minHeight="100px" display="flex" flexDirection="column" justifyContent="flex-end">
              <Box mt={2}>
                <Button variant="contained" color="primary" fullWidth type="submit" size="large" disabled={Boolean(isSubmitting)}>
                  <FormattedMessage id="auth.signin.cta" />
                  {isSubmitting && <CircularProgress size={25} />}
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
      <Dialog open={isForgotPasswordOpen} onClose={closeForgotPasswordDialog} fullScreen={isMobile}>
        <Container className={classes.container}>
          <Box mt={3}>
            <IconButton color="primary" size="medium" className={classes.closeIcon} onClick={closeForgotPasswordDialog}>
              <Close />
            </IconButton>
            {forgotPasswordStep === AuthStep.FORGOT_PASSWORD && (
              <ForgotPasswordForm
                handleForgotPasswordStep={handleForgotPasswordStep}
                onGoBack={closeForgotPasswordDialog}
                emailInput={forgotPasswordEmailInput}
              />
            )}
            {forgotPasswordStep === AuthStep.FORGOT_PASSWORD_SUCCESS && (
              <ForgotPasswordSuccess handleForgotPasswordStep={handleForgotPasswordStep} emailInput={forgotPasswordEmailInput} />
            )}
          </Box>
        </Container>
      </Dialog>
    </>
  );
};

SignInForm.propTypes = {
  handleStepChange: PropTypes.func,
  emailInput: PropTypes.string,
  openForgotPassword: PropTypes.bool,
};

export default SignInForm;
