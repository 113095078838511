import type { FC } from 'react';
import React, { useRef, useState } from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { Box, Divider, List, ListItem as MuiListItem, makeStyles, Menu, MenuItem, Typography, withStyles } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Theme } from 'src/theme';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'row',
  },
  popover: {
    backgroundColor: theme.palette.background.default,
    width: 235,
    marginTop: 1,
    borderRadius: 8,
    border: '1px solid #CBCBCB',
    boxShadow: 'none',
  },
  menuItem: {
    minWidth: 282,
    paddingLeft: 24,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const ListItem = withStyles({
  root: {
    '&$selected': {
      backgroundColor: 'transparent',
      color: '#000000',
    },
    '&$selected:hover': {
      backgroundColor: 'transparent',
      color: '#000000',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#000000',
    },
  },
  selected: {},
})(MuiListItem);

const DesktopOnlyTopBarOptions: FC = () => {
  const classes = useStyles();
  const ref = useRef<any>(null);
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const isPlaces = useRouteMatch('/places');
  const isJobs = useRouteMatch('/jobs');

  const openMenu = (): void => {
    setMenuOpen(true);
  };

  const closeMenu = (): void => {
    setMenuOpen(false);
  };

  const ShopsText = <FormattedMessage id="menu.partner.shops" />;
  const JobsText = <FormattedMessage id="menu.partner.jobs" />;

  return (
    <Box>
      <List component="nav" className={classes.root} disablePadding dense>
        <Box mr={5}>
          <ListItem button disableGutters {...{ component: RouterLink, to: '/places' }}>
            <Typography color="primary" variant="button">
              {isPlaces ? <b>{ShopsText}</b> : <>{ShopsText}</>}
            </Typography>
          </ListItem>
        </Box>
        <Box mr={5}>
          <ListItem button disableGutters {...{ component: RouterLink, to: '/jobs' }}>
            <Typography color="primary" variant="button">
              {isJobs ? <b>{JobsText}</b> : <>{JobsText}</>}
            </Typography>
          </ListItem>
        </Box>
        <Box>
          <ListItem disableGutters button onClick={openMenu} ref={ref}>
            <Typography color="primary" variant="button">
              <FormattedMessage id="menu.about.oxy" />
            </Typography>
            {isMenuOpen ? <ExpandLess color="inherit" /> : <ExpandMore color="inherit" />}
          </ListItem>
        </Box>
      </List>
      <Menu
        onClose={closeMenu}
        onClick={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isMenuOpen}
      >
        <MenuItem className={classes.menuItem} component={RouterLink} to="/about">
          <Typography color="primary" variant="caption">
            <FormattedMessage id="menu.who.are.we" />
          </Typography>
        </MenuItem>
        <Divider variant="middle" style={{ marginTop: 10, marginBottom: 10, marginLeft: 24, marginRight: 24 }} />
        <MenuItem className={classes.menuItem} component={RouterLink} to="/for-employers">
          <Typography color="primary" variant="caption">
            <FormattedMessage id="menu.for.shops" />
          </Typography>
        </MenuItem>
        <MenuItem className={classes.menuItem} component={RouterLink} to="/for-employees">
          <Typography color="primary" variant="caption">
            <FormattedMessage id="menu.for.employees" />
          </Typography>
        </MenuItem>
        <MenuItem className={classes.menuItem} component={RouterLink} to="/for-members">
          <Typography color="primary" variant="caption">
            <FormattedMessage id="menu.for.members" />
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default DesktopOnlyTopBarOptions;
