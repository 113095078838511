import React, { FC, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { AuthStep, ShopCodeStep } from 'src/constants/states';
import { Box, Button, CircularProgress, Divider, Link, makeStyles, TextField, Typography } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useHistory } from 'react-router-dom';
import { isEmployerByShopInfo, isNameTypeOfEmail, OxyPlaceInfo, ProfileUpdateResponse, UserUpdateRequest } from 'src/types/user';
import { updateUser } from 'src/lib/userService';
import useAuth from 'src/hooks/useAuth';
import { Theme } from 'src/theme';
import { AddCircleOutline, Help } from '@material-ui/icons';
import { deleteFromStorage, getFromStorage } from 'src/lib/appStorage';
import { logError } from 'src/lib/utils';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import AvatarWithImageChange from 'src/components/AvatarWithImageChange';
import OxyCodeField, { SHOP_CODE_LENGTH } from './OxyCodeField';

interface ProfileFormProps {
  handleStepChange?: (authStep: AuthStep) => void;
  onExit?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    position: 'relative',
    textDecoration: 'none',
    cursor: 'pointer',
    fontFamily: 'Ubuntu',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.25,
    color: theme.palette.secondary.light,
    '&.primaryColor': { color: theme.palette.primary.dark },
    '&:hover': {
      '&.primaryColor': { color: theme.palette.primary.dark },
      color: theme.palette.secondary.light,
      textDecoration: 'none',
    },
    '&::after': {
      content: `''`,
      position: 'absolute',
      width: '100%',
      height: '1.2px',
      top: 18,
      bottom: 0,
      left: 0,
      backgroundColor: theme.palette.secondary.light,
      '&.primaryColor': { backgroundColor: theme.palette.primary.dark },
      visibility: 'visible',
      transition: '0.5s ease all .3s',
      [theme.breakpoints.down('sm')]: {
        transition: 'none',
      },
    },
    '&:hover::after': {
      width: 0,
      visibility: 'hidden',
      transition: '0.3s ease all',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        visibility: 'visible',
        transition: 'none',
      },
    },
  },
  buttonAddCode: {
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  primaryText: {
    color: theme.palette.primary.dark,
  },
  greyText: {
    color: theme.palette.secondary.dark,
  },
  emailText: {
    '& .MuiOutlinedInput-input': {
      color: theme.palette.secondary.dark,
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.customColours.grey7,
      border: `1px ${theme.palette.background.paper}`,
      borderRadius: 8,
    },
    '& .MuiFormLabel-root': {
      backgroundColor: 'white',
    },
  },
  rectangle: {
    alignItems: 'center',
    display: 'flex',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2),
    borderRadius: 8,
  },
}));

const ProfileForm: FC<ProfileFormProps> = ({ handleStepChange, onExit }) => {
  const classes = useStyles();
  const { user, updateProfile } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const history = useHistory();
  const intl = useIntl();
  const [isInfoBoxOpen, setIsInfoBoxOpen] = useState(false);
  const [hasShopCode, setHasShopCode] = useState(false);
  const shopCode = getFromStorage('shopCode'); // read a possible entry of 'shopCode' in localstorage

  useEffect(() => {
    if (shopCode) {
      setHasShopCode(true);
    }
  }, [shopCode]);

  const shopCodeStartingValues = useMemo(
    () => Array.from(new Set(shopCode ? [...user.shopCodes, shopCode] : user.shopCodes)),
    [shopCode, user.shopCodes],
  );

  // Shop codes data
  const [dataJustCodes, setDataJustCodes] = useState<string[]>(shopCodeStartingValues);
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [keysForReactElements, setKeysForReactElements] = useState<string[]>(shopCodeStartingValues.map((_: string) => uuidv4())); // Get as many elements as there are codes
  const [dataMapOfCodesToShops, setDataMapOfCodesToShops] = useState<Map<string, OxyPlaceInfo>>(new Map<string, OxyPlaceInfo>());

  const allSuccess = useMemo(
    () =>
      dataJustCodes.length === dataMapOfCodesToShops.size &&
      Array.from(dataMapOfCodesToShops.values()).every(
        (eachEntry) => eachEntry.shopCodeStep === ShopCodeStep.SUCCESS_EMPLOYEE || eachEntry.shopCodeStep === ShopCodeStep.SUCCESS_EMPLOYER,
      ),
    [dataMapOfCodesToShops, dataJustCodes],
  );

  const atLeastOneSuccess = useMemo(
    () =>
      Array.from(dataMapOfCodesToShops.values()).some(
        (eachEntry) => eachEntry.shopCodeStep === ShopCodeStep.SUCCESS_EMPLOYEE || eachEntry.shopCodeStep === ShopCodeStep.SUCCESS_EMPLOYER,
      ),
    [dataMapOfCodesToShops],
  );

  const shouldShowShopCodeTip = !atLeastOneSuccess;
  const allowShowOxyCode = allSuccess || dataJustCodes.length === 0;

  const openShopCodeInfo = (): void => {
    setIsInfoBoxOpen(true);
  };

  const handleShowShopCode = (): void => {
    setKeysForReactElements((current) => [...current, uuidv4()]);
    setDataJustCodes((current) => [...current, '']);
  };

  const handleSubmit = (profileUpdateResponse: ProfileUpdateResponse): void => {
    deleteFromStorage('shopCode'); // delete a possible entry of 'shopCode' in localstorage
    if (handleStepChange) {
      handleStepChange(AuthStep.FINISH);
      if (hasShopCode && profileUpdateResponse && isEmployerByShopInfo(profileUpdateResponse)) {
        history.push(`/my-place?edit=true`);
      } else {
        history.push('/dashboard');
      }
    } else {
      handleExit(profileUpdateResponse);
    }
  };

  const handleExit = (profileUpdateResponse?: ProfileUpdateResponse): void => {
    if (onExit) {
      onExit();
    } else if (hasShopCode && profileUpdateResponse && isEmployerByShopInfo(profileUpdateResponse)) {
      history.push(`/my-place?edit=true`);
    } else {
      history.push('/dashboard');
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box>
          <Typography color="textPrimary" variant="h3">
            <FormattedMessage id="profile.form.title" />
          </Typography>
        </Box>
        <Box>
          <Box mt={3} className={classes.rectangle}>
            <Box flex="auto">
              <AvatarWithImageChange avatarFirebasePath={`users/${user.id}`} />
            </Box>
            <Box flex="auto" padding={2}>
              <Typography color="secondary" variant="caption">
                <FormattedMessage id="profile.form.shopCode.tip1" values={{ tip: <b>Pro Tip:</b> }} />
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box mt={5}>
        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            firstname: user.firstname ?? (isNameTypeOfEmail(user) ? '' : user.name.split(' ')[0]),
            lastname: user.lastname ?? (isNameTypeOfEmail(user) ? '' : user.name.split(' ')[1]),
            justShopCodes: dataJustCodes,
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            firstname: Yup.string()
              .nullable()
              .required(intl.formatMessage({ id: 'profile.form.firstname.required' })),
            lastname: Yup.string()
              .nullable()
              .required(intl.formatMessage({ id: 'profile.form.lastname.required' })),
            justShopCodes: Yup.array().of(
              Yup.string()
                .nullable()
                .length(SHOP_CODE_LENGTH, intl.formatMessage({ id: 'profile.form.shopCode.length' }, { length: SHOP_CODE_LENGTH })),
            ),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const userUpdateRequest: UserUpdateRequest = {
                firstname: values.firstname,
                lastname: values.lastname,
                mapOfShopCodes: dataMapOfCodesToShops,
              };
              const userUpdateResponse = await updateUser(user.id, user.shopCodes, userUpdateRequest);
              if (isMountedRef.current) {
                const profileUpdateResponse = await updateProfile(userUpdateResponse);
                setStatus({ success: true });
                setSubmitting(false);
                enqueueSnackbar(intl.formatMessage({ id: 'profile.form.success' }), {
                  variant: 'success',
                });
                handleSubmit(profileUpdateResponse);
              }
            } catch (err) {
              logError(err);
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
                enqueueSnackbar(intl.formatMessage({ id: 'profile.form.error' }), {
                  variant: 'error',
                });
              }
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Typography variant="h4">
                <FormattedMessage id="profile.form.personal" />
              </Typography>
              <TextField
                fullWidth
                error={Boolean(touched.firstname && errors.firstname)}
                helperText={touched.firstname && errors.firstname}
                label={intl.formatMessage({ id: 'profile.form.firstname' })}
                name="firstname"
                type="text"
                onChange={handleChange}
                value={values.firstname}
                variant="outlined"
                color="secondary"
                margin="normal"
                size="small"
              />
              <TextField
                fullWidth
                error={Boolean(touched.lastname && errors.lastname)}
                helperText={touched.lastname && errors.lastname}
                label={intl.formatMessage({ id: 'profile.form.lastname' })}
                name="lastname"
                type="text"
                onChange={handleChange}
                value={values.lastname}
                variant="outlined"
                color="secondary"
                margin="normal"
                size="small"
              />
              <Box>
                <Typography className={classes.greyText} variant="caption">
                  <FormattedMessage id="profile.form.name.info" />
                </Typography>
              </Box>
              <TextField
                className={classes.emailText}
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={user.email}
                disabled
                variant="outlined"
                color="secondary"
                margin="normal"
                size="small"
              />
              {values.justShopCodes?.length > 0 && (
                <Box mt={3}>
                  <Typography variant="h4">
                    <FormattedMessage id="profile.form.shopCode.partner.info" />
                    <Typography variant="caption">
                      <FormattedMessage id="profile.form.shopCode.partner.optional" />
                    </Typography>
                  </Typography>
                </Box>
              )}
              {values.justShopCodes &&
                values.justShopCodes.map((eachShopInfo, shopInfoIndex) => {
                  const shopCodeEntryErrors = errors.justShopCodes?.[shopInfoIndex];
                  const shopCodeEntryTouched = touched.justShopCodes?.[shopInfoIndex] ?? false;

                  const setFieldValueForArray = (index: number, newValue: string) => {
                    const newArray: string[] = [...values.justShopCodes];
                    newArray[index] = newValue;
                    setDataJustCodes(newArray);
                  };
                  const removeIndexValueFromArray = (index: number) => {
                    const newArray: string[] = [...values.justShopCodes];
                    const deletedShopCodes = newArray.splice(index, 1);
                    keysForReactElements.splice(index, 1);
                    setFieldValue('justShopCodes', newArray, true);
                    setDataJustCodes(newArray);
                    setDataMapOfCodesToShops((currentMap) => {
                      const clonedMap = new Map(currentMap);
                      deletedShopCodes.forEach((eachShopCodeToDelete) => clonedMap.delete(eachShopCodeToDelete));
                      return clonedMap;
                    });
                  };

                  const addValidOxyPlaceToMap = (validOxyPlace: OxyPlaceInfo) => {
                    setDataMapOfCodesToShops((currentMap) => {
                      const clonedMap = new Map(currentMap);
                      clonedMap.set(validOxyPlace.shopCode, validOxyPlace);
                      return clonedMap;
                    });
                  };

                  return (
                    <Box key={keysForReactElements[shopInfoIndex]} mb={4}>
                      <OxyCodeField
                        errors={shopCodeEntryErrors}
                        shopCode={eachShopInfo}
                        handleBlur={handleBlur}
                        handleChange={(e) => {
                          handleChange(e);
                          setFieldValueForArray(shopInfoIndex, e.target.value as string);
                        }}
                        touched={shopCodeEntryTouched}
                        userRemovedOxyPlace={() => removeIndexValueFromArray(shopInfoIndex)}
                        userSetValidOxyPlace={addValidOxyPlaceToMap}
                      />
                    </Box>
                  );
                })}

              {allowShowOxyCode && (
                <>
                  <Box mt={3}>
                    <Divider variant="fullWidth" />
                  </Box>
                  <Button variant="text" className={classes.buttonAddCode} fullWidth onClick={handleShowShopCode}>
                    <Typography variant="caption" className={classes.primaryText}>
                      <FormattedMessage id="profile.form.shopCode.add" />
                    </Typography>
                    <AddCircleOutline style={{ color: '#215E6C', marginLeft: 'auto' }} />
                  </Button>
                </>
              )}
              {shouldShowShopCodeTip && (
                <>
                  {!isInfoBoxOpen ? (
                    <Box ml="3px" display="flex" alignItems="center">
                      <Help style={{ color: '#9E9E9E', marginRight: 5 }} />
                      <Link className={classes.link} onClick={openShopCodeInfo}>
                        <FormattedMessage id="profile.form.shopCode.msg" />
                      </Link>
                    </Box>
                  ) : (
                    <Box className={classes.rectangle} mt={1.5}>
                      <Box>
                        <Box>
                          <Typography color="secondary" variant="caption">
                            <FormattedMessage id="profile.form.shopCode.info" />
                          </Typography>
                        </Box>
                        <Box mt={1}>
                          <Link href="/contact" className={clsx(classes.link, 'primaryColor')}>
                            <FormattedMessage id="profile.form.shopCode.cta" />
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </>
              )}
              <Box mt={4.25}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  size="large"
                  disabled={Boolean(isSubmitting || !allSuccess)}
                  // For some reason, "touched" here is always undefined
                >
                  <FormattedMessage id="profile.form.cta" />
                  {isSubmitting && <CircularProgress size={25} />}
                </Button>
              </Box>
              <Box mt={2}>
                <Button
                  disabled={Boolean(isSubmitting || (allSuccess && (!user.firstname || !user.lastname)))}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  size="large"
                  onClick={() => handleExit(null)}
                >
                  <Typography color="inherit" variant="button">
                    <FormattedMessage id="profile.form.cta2" />
                  </Typography>
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
};

ProfileForm.propTypes = {
  handleStepChange: PropTypes.func,
  onExit: PropTypes.func,
};

export default ProfileForm;
