import { FC } from 'react';
import { useGoogleOneTapLogin } from 'react-google-one-tap-login';
import useAuth from 'src/hooks/useAuth';
import { AuthContextValue } from 'src/contexts/FirebaseAuthContext';
import { triggerSignupEmail } from 'src/lib/userService';
import useLocale from 'src/hooks/useLocale';

const isGoogleOneTapLoginDisabled = process.env.REACT_APP_GOOGLE_ONE_TAP_LOGIN_ENABLED === 'false';
const googleOneTapLoginClientId = process.env.REACT_APP_GOOGLE_ONE_TAP_LOGIN_CLIENT_ID;

const GoogleOneTapLogin: FC = () => {
  const { locale } = useLocale();
  const { isAuthenticated, signInWithGoogle } = useAuth() as AuthContextValue;

  const handleGoogleClick: () => Promise<void> = async () => {
    return signInWithGoogle().then((newUserAndEmail) => {
      const { isNewUser, email } = newUserAndEmail;
      return handleProviderClick(isNewUser, email);
    });
  };

  const handleProviderClick = async (isNewUser: boolean, email: string) => {
    if (isNewUser) {
      await triggerSignupEmail(locale, email);
    }
  };

  return useGoogleOneTapLogin({
    disabled: isGoogleOneTapLoginDisabled || isAuthenticated,
    onError: (error) => Promise.reject(new Error(`error in useGoogleOneTapLogin(): ${error}`)),
    googleAccountConfigs: {
      client_id: googleOneTapLoginClientId,
      callback: handleGoogleClick,
      auto_select: false,
      cancel_on_tap_outside: true,
    },
  });
};

export default GoogleOneTapLogin;
