import type { FC } from 'react';
import React from 'react';
import { Route, Router } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { createBrowserHistory } from 'history';
import { createOxyTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';
import { StylesProvider, ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import GoogleOneTapLogin from 'src/components/GoogleOneTapLogin';
import { AuthProvider } from 'src/contexts/FirebaseAuthContext';
import MySnackbarProvider from 'src/components/MySnackbarProvider';
import ErrorView from 'src/views/errors/ErrorView';
import * as Sentry from '@sentry/react';
import './index.css';

declare global {
  interface Window {
    Modernizr: any;
  }
}
const history = createBrowserHistory();

const App: FC = () => {
  const theme = createOxyTheme();
  return (
    <ThemeProvider theme={theme}>
      <StylesProvider>
        <MySnackbarProvider>
          <Router history={history}>
            <QueryParamProvider ReactRouterRoute={Route}>
              <AuthProvider>
                <GlobalStyles />
                <ScrollReset />
                <GoogleOneTapLogin />
                <Sentry.ErrorBoundary fallback={<ErrorView />}>{renderRoutes(routes)}</Sentry.ErrorBoundary>
              </AuthProvider>
            </QueryParamProvider>
          </Router>
        </MySnackbarProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
