export default process.env.NODE_ENV !== 'production';
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;
export const OXY_TIME_LIMIT = process.env.REACT_APP_OXY_TIME_LIMIT;

export enum EnvType {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
}
