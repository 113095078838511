import type { FC } from 'react';
import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import type { Theme } from 'src/theme';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import GoogleIcon from 'src/assets/img/google.svg';
import FacebookIcon from 'src/assets/img/facebook.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { AuthContextValue } from 'src/contexts/FirebaseAuthContext';
import { SnackbarAction, useSnackbar, VariantType } from 'notistack';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { hasFirebaseAuthError, logError } from 'src/lib/utils';
import { AuthStep } from 'src/constants/states';
import { useHistory } from 'react-router-dom';
import { triggerSignupEmail } from 'src/lib/userService';
import useLocale from 'src/hooks/useLocale';

interface SignUpFormProps {
  handleStepChange?: (authStep: AuthStep, email?: string) => void;
  emailInput?: string | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  providerButton: {
    boxShadow: 'none',
    border: '2px solid #CBCBCB',
    borderRadius: 8,
    backgroundColor: theme.palette.common.white,
  },
  providerIcon: {
    width: 16,
    height: 16,
    position: 'absolute',
    left: 15,
  },
  divider: {
    flexGrow: 1,
    border: '1px solid #CBCBCB',
  },
  dividerText: {
    margin: theme.spacing(2),
    color: theme.palette.secondary.dark,
  },
  link: {
    color: theme.palette.info.main,
    fontWeight: 400,
  },
}));

const SignUpForm: FC<SignUpFormProps> = ({ handleStepChange, emailInput }) => {
  const classes = useStyles();
  const { createUserWithEmailAndPassword, signInWithGoogle, signInWithFacebook } = useAuth() as AuthContextValue;
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { locale } = useLocale();
  const history = useHistory();
  const intl = useIntl();

  const handleGoogleClick = async () => {
    const { isNewUser, email } = await signInWithGoogle();
    return handleProviderClick(isNewUser, email);
  };

  const handleFacebookClick = async () => {
    const { isNewUser, email } = await signInWithFacebook();
    return handleProviderClick(isNewUser, email);
  };

  const handleProviderClick = async (isNewUser: boolean, email: string) => {
    if (handleStepChange) {
      if (isNewUser) {
        handleStepChange(AuthStep.WELCOME);
      } else {
        handleStepChange(AuthStep.FINISH);
      }
    }
    if (isNewUser) {
      await triggerSignupEmail(locale, email);
    }
  };

  const handleSignupSuccess = (): void => {
    if (handleStepChange) {
      handleStepChange(AuthStep.WELCOME);
    }
  };

  return (
    <>
      <Box>
        <Button className={classes.providerButton} fullWidth size="large" onClick={handleGoogleClick}>
          <img className={classes.providerIcon} alt="Google" src={GoogleIcon} />
          <Typography color="primary" variant="button">
            <FormattedMessage id="auth.signon.google" />
          </Typography>
        </Button>
      </Box>
      <Box mt={2}>
        <Button className={classes.providerButton} fullWidth size="large" onClick={handleFacebookClick}>
          <img className={classes.providerIcon} alt="Facebook" src={FacebookIcon} />
          <Typography color="primary" variant="button">
            <FormattedMessage id="auth.signon.facebook" />
          </Typography>
        </Button>
      </Box>
      <Box mt={2} display="flex" alignItems="center">
        <Divider className={classes.divider} orientation="horizontal" />
        <Typography variant="caption" className={classes.dividerText}>
          <FormattedMessage id="auth.signon.email" />
        </Typography>
        <Divider className={classes.divider} orientation="horizontal" />
      </Box>
      <Formik
        initialValues={{
          email: emailInput ?? '',
          password: '',
          showPassword: false,
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .nullable()
            .email(intl.formatMessage({ id: 'auth.form.email.valid' }))
            .required(intl.formatMessage({ id: 'auth.form.email.required' })),
          password: Yup.string()
            .nullable()
            .min(6, intl.formatMessage({ id: 'auth.form.password.valid' }))
            .required(intl.formatMessage({ id: 'auth.form.password.required' })),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await createUserWithEmailAndPassword(values.email, values.password);
            await triggerSignupEmail(locale, values.email);
            if (isMountedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
              handleSignupSuccess();
            }
          } catch (err) {
            logError(err);
            let errorMessage = intl.formatMessage({ id: 'auth.signup.error' });
            let variantType = 'error' as VariantType;
            let action = null as SnackbarAction;
            if (hasFirebaseAuthError(err, 'auth/email-already-in-use')) {
              errorMessage = intl.formatMessage({ id: 'auth.signup.error.email' });
              variantType = 'warning';
              action = (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    if (handleStepChange) {
                      handleStepChange(AuthStep.SIGNIN, values.email);
                    } else {
                      history.push(`/signin?email=${values.email}`);
                    }
                  }}
                >
                  <Typography color="textSecondary" variant="button">
                    <FormattedMessage id="auth.signin" />
                  </Typography>
                </Button>
              );
            }
            setStatus({ success: false });
            setErrors({ submit: errorMessage });
            setSubmitting(false);
            enqueueSnackbar(errorMessage, {
              variant: variantType,
              action,
            });
          }
        }}
      >
        {({ errors, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              fullWidth
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              label={intl.formatMessage({ id: 'auth.form.email' })}
              name="email"
              type="email"
              onChange={handleChange}
              value={values.email}
              variant="outlined"
              color="secondary"
              margin="normal"
            />
            <TextField
              fullWidth
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              label={intl.formatMessage({ id: 'auth.form.password' })}
              name="password"
              type={values.showPassword ? 'text' : 'password'}
              onChange={handleChange}
              value={values.password}
              variant="outlined"
              color="secondary"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setFieldValue('showPassword', !values.showPassword)}>
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box ml="3px" minHeight="45px">
              <Typography color="secondary" variant="caption">
                <FormattedMessage id="auth.form.password.valid" />
              </Typography>
            </Box>
            <Box mt={2} minHeight="100px" display="flex" flexDirection="column" justifyContent="flex-end">
              <Box>
                <Typography color="primary" variant="caption">
                  <FormattedMessage id="auth.signup.message.part1" />
                  &nbsp;
                  <Link href="/terms" target="_blank" className={classes.link}>
                    <FormattedMessage id="auth.signup.message.part2" />
                  </Link>
                  &nbsp;
                  <FormattedMessage id="auth.signup.message.part3" />
                  &nbsp;
                  <Link href="/privacy-policy" target="_blank" className={classes.link}>
                    <FormattedMessage id="auth.signup.message.part4" />
                  </Link>
                </Typography>
              </Box>
              <Box mt={2}>
                <Button variant="contained" color="primary" fullWidth type="submit" size="large" disabled={Boolean(isSubmitting)}>
                  <FormattedMessage id="auth.signup.cta" />
                  {isSubmitting && <CircularProgress size={25} />}
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

SignUpForm.propTypes = {
  handleStepChange: PropTypes.func,
  emailInput: PropTypes.string,
};

export default SignUpForm;
