export enum AuthStep {
  SIGNIN = 'SIGNIN',
  SIGNUP = 'SIGNUP',
  WELCOME = 'WELCOME',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  EDIT_PROFILE = 'EDIT_PROFILE',
  FINISH = 'FINISH',
}

export enum ShopCodeStep {
  INIT = 'INIT',
  HAS_ERROR = 'HAS_ERROR',
  CHECKING = 'CHECKING',
  NOT_FOUND = 'NOT_FOUND',
  SUCCESS_EMPLOYEE = 'SUCCESS_EMPLOYEE',
  SUCCESS_EMPLOYER = 'SUCCESS_EMPLOYER',
}
