import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(isoWeek);
dayjs.extend(relativeTime);
dayjs.extend(localeData);

export const nameofFactory =
  <T>() =>
  (name: keyof T) =>
    name as string;

export const hasFirebaseAuthError = (err: any, errorCode: string): boolean => {
  return err.code === errorCode;
};

export const logError = (err: any): void => {
  // console.log(err);
  Sentry.captureException(err);
};

export const wait = (time: number): Promise<void> => {
  return new Promise((res) => setTimeout(res, time));
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
};

export function convertObjectToMap<V>(obj): Map<string, V> {
  const map = new Map<string, V>();
  if (obj) {
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i += 1) {
      map.set(keys[i], obj[keys[i]]);
    }
  }
  return map;
}

export function letUserShareUrl(text: string): Promise<void> {
  // Check if navigator.share is supported by the browser
  if (navigator.share) {
    return navigator.share({
      url: text,
    });
  }

  return Promise.reject(new Error('Web Share API is not supported by your browser'));
}
