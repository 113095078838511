const STORAGE_ROOT_KEY = 'oxy.community';
const ONE_YEAR_IN_SECONDS = 31536000;

interface StorageItem {
  value: any;
  expiry: number;
}

export const getFromStorage = (key: string): any => {
  const rootStr = localStorage.getItem(STORAGE_ROOT_KEY);
  // if the root item doesn't exist, put an empty root item in the storage and return null
  if (!rootStr) {
    localStorage.setItem(STORAGE_ROOT_KEY, '{}');
    return null;
  }
  const root = JSON.parse(rootStr);
  // if the specific item with the given key doesn't exist, return null
  if (!root[key]) {
    return null;
  }

  const item: StorageItem = root[key];
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from the root object, update the storage and return null
    delete root[key];
    localStorage.setItem(STORAGE_ROOT_KEY, JSON.stringify(root));
    return null;
  }
  return item.value;
};

// ttl: in seconds, default expiration: 1 year
export const setToStorage = (key: string, value: any, ttl = ONE_YEAR_IN_SECONDS): void => {
  const rootStr = localStorage.getItem(STORAGE_ROOT_KEY);
  // if the root item doesn't exist, put an empty root item in the storage, else parse the root item to JSON
  let root;
  if (!rootStr) {
    root = {};
    localStorage.setItem(STORAGE_ROOT_KEY, JSON.stringify(root));
  } else {
    root = JSON.parse(rootStr);
  }

  // create an item which contains the original value and the time to expire and update the root item in storage
  const now = new Date();
  root[key] = {
    value,
    expiry: now.getTime() + ttl * 1000,
  };
  localStorage.setItem(STORAGE_ROOT_KEY, JSON.stringify(root));
};

export const deleteFromStorage = (key: string): void => {
  const rootStr = localStorage.getItem(STORAGE_ROOT_KEY);
  // if the root item doesn't exist, then return
  if (!rootStr) {
    return;
  }
  // if the specific item with the given key doesn't exist, then return
  const root = JSON.parse(rootStr);
  if (!root[key]) {
    return;
  }
  delete root[key];
  localStorage.setItem(STORAGE_ROOT_KEY, JSON.stringify(root));
};
