import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const history = useHistory();

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/signin',
          search: history.location.search,
        }}
      />
    );
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
