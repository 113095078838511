import type { FC } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Container, Hidden, IconButton, makeStyles, Toolbar } from '@material-ui/core';
import Logo from 'src/components/Logo';
import type { Theme } from 'src/theme';
import Account from 'src/layouts/MainLayout/TopBar/Account';
import DesktopOnlyTopBarOptions from './DesktopOnlyTopBarOptions';
import LocaleSelector from './LocaleSelector';
import burgerMenuIcon from './burger-menu.svg';

interface TopBarProps {
  onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: 'none',
    backgroundColor: theme.palette.background.default,
    borderBottom: '1px solid black',
  },
  toolbar: {
    minHeight: 70,
    [theme.breakpoints.down('sm')]: {
      minHeight: 60,
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
}));

const TopBar: FC<TopBarProps> = ({ onMobileNavOpen }) => {
  const classes = useStyles();
  return (
    <AppBar className={classes.root}>
      <Container disableGutters maxWidth="lg">
        <Toolbar disableGutters className={classes.toolbar}>
          <Hidden mdUp>
            <IconButton style={{ padding: 0, marginRight: 20 }} onClick={onMobileNavOpen}>
              <img src={burgerMenuIcon} alt="Menu" />
            </IconButton>
          </Hidden>
          <Box mt={1}>
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
          <Box flexGrow={1} />
          <Hidden smDown>
            <Box>
              <DesktopOnlyTopBarOptions />
            </Box>
          </Hidden>
          <Box ml={3}>
            <LocaleSelector />
          </Box>
          <Box ml={3}>
            <Account />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

TopBar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
