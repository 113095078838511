import React, { FC, useState } from 'react';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import clsx from 'clsx';
import { Avatar, Badge, CircularProgress, makeStyles, Tooltip } from '@material-ui/core';
import { Theme } from 'src/theme';
import useAuth from 'src/hooks/useAuth';
import { updateEntityAvatar } from 'src/lib/userService';
import { getResizedURL, removeFileFromStorage, uploadFileToStorage } from 'src/lib/firebaseStorage';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { logError } from 'src/lib/utils';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';
import PropTypes from 'prop-types';
import { oxyWebsiteFirestore } from '../lib/oxyFirebase';
import { entityWithAvatarFirebaseConverter } from '../types/firebaseConverters/genericConverter';

interface AvatarWithImageChangeProps {
  avatarFirebasePath: string;
  onSuccess?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: 100,
    width: 100,
  },
  avatarBadge: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '50%',
    fontSize: 28,
    cursor: 'pointer',
  },
  avatarAdd: {
    color: theme.palette.primary.dark,
  },
  avatarRemove: {
    color: theme.palette.secondary.dark,
  },
}));

const AvatarWithImageChange: FC<AvatarWithImageChangeProps> = ({ avatarFirebasePath, onSuccess }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const { user, updateAvatar } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const avatarImageUrlDocument = doc(oxyWebsiteFirestore, avatarFirebasePath).withConverter(entityWithAvatarFirebaseConverter);
  const [avatarImageUrl, avatarImageUrlLoading] = useDocumentData(avatarImageUrlDocument);

  const isMe = avatarFirebasePath.endsWith(user.id);
  const avatarPath = `/${avatarFirebasePath}/avatar`;

  const addAvatar = async (file: File) => {
    setLoading(true);
    try {
      const uploadedFile = await uploadFileToStorage(file, avatarPath);
      const resizedURL = await getResizedURL(uploadedFile.name, avatarPath);
      await updateEntityAvatar(avatarFirebasePath, resizedURL).then(() => {
        if (onSuccess) {
          onSuccess();
        }
      });
      if (isMe) updateAvatar(resizedURL);
    } catch (err) {
      logError(err);
      enqueueSnackbar(intl.formatMessage({ id: 'profile.avatar.add.error' }), {
        variant: 'error',
      });
    }
    setLoading(false);
  };

  const removeAvatar = async () => {
    setLoading(true);
    try {
      await removeFileFromStorage(avatarImageUrl.avatar);
      await updateEntityAvatar(avatarFirebasePath, null);
      if (isMe) updateAvatar(null);
    } catch (err) {
      logError(err);
      enqueueSnackbar(intl.formatMessage({ id: 'profile.avatar.remove.error' }), {
        variant: 'error',
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={
          loading ? (
            <></>
          ) : !avatarImageUrlLoading && avatarImageUrl?.avatar ? (
            <Tooltip title={intl.formatMessage({ id: 'profile.avatar.remove' })} placement="right-start">
              <RemoveCircle onClick={removeAvatar} className={clsx(classes.avatarBadge, classes.avatarRemove)} />
            </Tooltip>
          ) : (
            <>
              <input style={{ display: 'none' }} id="badge-add-avatar" type="file" onChange={(e) => addAvatar(e.currentTarget.files[0])} />
              {/* eslint-disable-next-line */}
              <label htmlFor="badge-add-avatar">
                <Tooltip title={intl.formatMessage({ id: 'profile.avatar.add' })} placement="right-start">
                  <AddCircle className={clsx(classes.avatarBadge, classes.avatarAdd)} />
                </Tooltip>
              </label>
            </>
          )
        }
      >
        {loading || avatarImageUrlLoading ? (
          <Avatar alt="Avatar" className={classes.avatar}>
            <CircularProgress size={30} />
          </Avatar>
        ) : (
          <Avatar alt="Avatar" className={classes.avatar} src={avatarImageUrl.avatar} />
        )}
      </Badge>
    </>
  );
};

AvatarWithImageChange.propTypes = {
  avatarFirebasePath: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
};

export default AvatarWithImageChange;
