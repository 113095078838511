import type { FC } from 'react';
import React, { createContext, ReactNode, useState } from 'react';
import PropTypes from 'prop-types';
import { Locale } from 'src/constants/locales';
import locale_en from 'src/translations/en.json';
import locale_el from 'src/translations/el.json';
import { IntlProvider } from 'react-intl';
import { getFromStorage, setToStorage } from 'src/lib/appStorage';
import dayjs from 'dayjs';
import { oxyWebsiteAuth } from '../lib/oxyFirebase';

export interface LocaleContextValue {
  locale: Locale;
  updateLocale: (newLocale: Locale) => void;
}

interface AppLocaleContextProps {
  children?: ReactNode;
}

const initLocale = (): Locale => {
  let initialLocale = getFromStorage('locale');
  if (!initialLocale) {
    initialLocale = navigator.language.split(/[-_]/)[0] === Locale.EL ? Locale.EL : Locale.EN;
  }
  setToStorage('locale', initialLocale);
  oxyWebsiteAuth.languageCode = initialLocale;
  dayjs.locale(initialLocale);
  return initialLocale;
};

export const LocaleContext = createContext<LocaleContextValue>({
  locale: Locale.EN,
  updateLocale: () => Promise.resolve(),
});

const locale = initLocale();
const locales: { [index: string]: any } = {
  en: locale_en,
  el: locale_el,
};

const AppLocaleContext: FC<AppLocaleContextProps> = ({ children }) => {
  const [value, setValue] = useState(locale);
  const [messages, setMessages] = useState(locales[locale]);

  const updateLocale = (newLocale: Locale): void => {
    setValue(newLocale);
    setMessages(locales[newLocale]);
    setToStorage('locale', newLocale);
    oxyWebsiteAuth.languageCode = newLocale;
    dayjs.locale(newLocale);
  };

  return (
    <LocaleContext.Provider value={{ locale: value, updateLocale }}>
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

AppLocaleContext.propTypes = {
  children: PropTypes.node,
};

export default AppLocaleContext;
