import React, { FC } from 'react';
import { Box, Link, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Theme } from 'src/theme';
import { AuthStep } from 'src/constants/states';
import PropTypes from 'prop-types';

interface ForgotPasswordSuccessProps {
  handleStepChange?: (authStep: AuthStep, email?: string | null) => void;
  handleForgotPasswordStep?: (authStep: AuthStep, email?: string | null) => void;
  emailInput?: string | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    position: 'relative',
    textDecoration: 'none',
    cursor: 'pointer',
    fontFamily: 'Ubuntu',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.25,
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
    '&::after': {
      content: `''`,
      position: 'absolute',
      width: '100%',
      height: 2,
      top: 22,
      bottom: 0,
      left: 0,
      backgroundColor: theme.palette.secondary.main,
      visibility: 'visible',
      transition: '0.5s ease all .3s',
      [theme.breakpoints.down('sm')]: {
        transition: 'none',
      },
    },
    '&:hover::after': {
      width: 0,
      visibility: 'hidden',
      transition: '0.3s ease all',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        visibility: 'visible',
        transition: 'none',
      },
    },
  },
}));

const ForgotPasswordSuccess: FC<ForgotPasswordSuccessProps> = ({ handleStepChange, handleForgotPasswordStep, emailInput }) => {
  const classes = useStyles();

  const handleGoBackClick = (): void => {
    if (handleStepChange) {
      handleStepChange(AuthStep.FORGOT_PASSWORD, emailInput);
    } else if (handleForgotPasswordStep) {
      handleForgotPasswordStep(AuthStep.FORGOT_PASSWORD, emailInput);
    }
  };

  return (
    <>
      <Box textAlign="center">
        <Typography color="textPrimary" variant="h3">
          <FormattedMessage id="forgot.password.title" />
        </Typography>
      </Box>
      <Box mt={5}>
        <Typography color="primary" variant="subtitle1">
          <FormattedMessage id="forgot.password.success" values={{ email: <b>{emailInput}</b> }} />
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography color="primary" variant="caption">
          <FormattedMessage id="forgot.password.success.info" />
        </Typography>
      </Box>
      <Box mt={3}>
        <Link onClick={handleGoBackClick} className={classes.link}>
          <FormattedMessage id="forgot.password.success.cta" />
        </Link>
      </Box>
    </>
  );
};

ForgotPasswordSuccess.propTypes = {
  handleStepChange: PropTypes.func,
  handleForgotPasswordStep: PropTypes.func,
  emailInput: PropTypes.string,
};

export default ForgotPasswordSuccess;
