import { nameofFactory } from './utils';
import { FirestoreUser } from '../types/user';
import { Shop } from '../types/shop';
import { Employment, FirestoreProfilePrivacySettings, FirestoreProfileShopAccess } from '../types/employment';
import { OxyJob } from '../types/oxyJob';

export const firestoreUserTypeInfo = nameofFactory<FirestoreUser>();
export const firestoreShopTypeInfo = nameofFactory<Shop>();
export const firestoreProfilePrivacySettingsTypeInfo = nameofFactory<FirestoreProfilePrivacySettings>();
export const firestoreEmploymentTypeInfo = nameofFactory<Employment>();
export const firestoreProfileShopAccessTypeInfo = nameofFactory<FirestoreProfileShopAccess>();
export const oxyJobTypeInfo = nameofFactory<OxyJob>();
