import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const sentryDsn = 'https://af734f83b1d84aa28042b52a05e69352@o765436.ingest.sentry.io/5794257';
const sentryEnabled = process.env.REACT_APP_SENTRY_ENABLED === 'true';

const initializeSentry = () => {
  if (sentryEnabled) {
    Sentry.init({
      dsn: sentryDsn,
      environment: process.env.REACT_APP_ENVIRONMENT,
      release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
      // @ts-ignore
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.7,
    });
  }
};

export default initializeSentry;
