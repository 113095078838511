// https://material-ui.com/customization/typography
export default {
  fontFamily: 'Ubuntu',
  h1: {
    fontSize: 54,
    fontWeight: 700,
    lineHeight: 1.25,
    '@media (max-width:600px)': {
      fontSize: 32,
    },
  },
  h2: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 1.4,
    '@media (max-width:600px)': {
      fontSize: 26,
    },
  },
  h3: {
    fontSize: 26,
    fontWeight: 700,
    lineHeight: 1.4,
    '@media (max-width:600px)': {
      fontSize: 22,
    },
  },
  h4: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.4,
    '@media (max-width:600px)': {
      fontSize: 18,
    },
  },
  h5: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.4,
  },
  h6: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
  },
  body1: {
    fontSize: 20,
    fontWeight: 300,
    lineHeight: 1.75,
    '@media (max-width:600px)': {
      fontSize: 18,
      lineHeight: 1.5,
    },
  },
  body2: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.75,
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 300,
    lineHeight: 1.25,
    '@media (max-width:600px)': {
      fontSize: 16,
      lineHeight: 1,
    },
  },
  subtitle2: {
    fontSize: 16,
    fontWeight: 300,
    lineHeight: 1,
  },
  caption: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  caption2: {
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  button: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
    textTransform: 'none',
  },
};
