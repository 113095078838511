import React, { Fragment, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import MainLayout from 'src/layouts/MainLayout';
import AuthGuard from 'src/components/AuthGuard';
import EmployerGuard from 'src/components/EmployerGuard';
import componentLoader from 'src/lib/componentLoader';
import GuestOrAnonymousGuard from './components/GuestOrAnonymousGuard';
import SalesPersonGuard from './components/SalesPersonGuard';
import EmployerOrSalesGuard from './components/EmployerOrSalesGuard';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, index) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/',
    layout: MainLayout,
    component: componentLoader(() => import('src/views/home/HomeView')),
  },
  {
    exact: true,
    path: '/for-members',
    layout: MainLayout,
    component: componentLoader(() => import('src/views/members/MemberView')),
  },
  {
    exact: true,
    path: '/for-employees',
    layout: MainLayout,
    component: componentLoader(() => import('src/views/employees/EmployeeView')),
  },
  {
    exact: true,
    path: '/for-employers',
    layout: MainLayout,
    component: componentLoader(() => import('src/views/employers/EmployerView')),
  },
  {
    exact: true,
    path: '/join-oxy-community',
    layout: MainLayout,
    component: componentLoader(() => import('src/views/employersLanding/EmployerView')),
  },
  {
    exact: true,
    path: '/contact',
    layout: MainLayout,
    component: componentLoader(() => import('src/views/contact/ContactView')),
  },
  {
    exact: true,
    path: '/support',
    layout: MainLayout,
    component: componentLoader(() => import('src/views/support/SupportView')),
  },
  {
    exact: true,
    path: '/about',
    layout: MainLayout,
    component: componentLoader(() => import('src/views/about/AboutView')),
  },
  {
    exact: true,
    path: '/faq',
    layout: MainLayout,
    component: componentLoader(() => import('src/views/faq/FaqView')),
  },
  {
    exact: true,
    path: '/terms',
    layout: MainLayout,
    component: componentLoader(() => import('src/views/terms/TermsView')),
  },
  {
    exact: true,
    path: '/privacy-policy',
    layout: MainLayout,
    component: componentLoader(() => import('src/views/terms/PrivacyPolicyView')),
  },
  {
    exact: true,
    guard: GuestOrAnonymousGuard,
    path: '/signin',
    layout: MainLayout,
    component: componentLoader(() => import('src/views/auth/SignInView')),
  },
  {
    exact: true,
    guard: GuestOrAnonymousGuard,
    path: '/signup',
    layout: MainLayout,
    component: componentLoader(() => import('src/views/auth/SignUpView')),
  },
  {
    exact: true,
    guard: GuestOrAnonymousGuard,
    path: '/auth-action',
    layout: MainLayout,
    component: componentLoader(() => import('src/views/auth/AuthActionView')),
  },
  {
    exact: true,
    guard: GuestOrAnonymousGuard,
    path: '/impersonation',
    layout: MainLayout,
    component: componentLoader(() => import('src/views/auth/ImpersonationView/ImpersonationForm')),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/welcome',
    layout: MainLayout,
    component: componentLoader(() => import('src/views/auth/WelcomeView')),
  },
  {
    exact: true,
    path: '/profile',
    guard: AuthGuard,
    layout: MainLayout,
    component: componentLoader(() => import('src/views/profile/ProfileView')),
  },
  {
    exact: true,
    path: '/dashboard',
    guard: AuthGuard,
    layout: MainLayout,
    component: componentLoader(() => import('src/views/dashboard/DashboardView')),
  },
  {
    exact: true,
    path: '/my-place',
    guard: EmployerGuard,
    layout: MainLayout,
    component: componentLoader(() => import('src/views/places/MyPlaceView')),
  },
  {
    exact: true,
    path: '/my-place/:placeId',
    guard: EmployerGuard,
    layout: MainLayout,
    component: componentLoader(() => import('src/views/places/MyPlaceView')),
  },
  {
    exact: true,
    path: '/places',
    layout: MainLayout,
    component: componentLoader(() => import('src/views/places/PlaceListView')),
  },
  {
    exact: true,
    path: '/places/:placeId',
    layout: MainLayout,
    component: componentLoader(() => import('src/views/places/PlaceView')),
  },
  {
    exact: true,
    path: '/sales/add-oxy-place',
    guard: SalesPersonGuard,
    layout: MainLayout,
    component: componentLoader(() => import('src/views/sales/AddOxyPlace')),
  },
  {
    exact: true,
    path: '/jobs',
    layout: MainLayout,
    component: componentLoader(() => import('src/views/jobs/listing/JobsListingPage')),
  },
  {
    exact: true,
    path: '/jobs/create',
    guard: EmployerOrSalesGuard,
    layout: MainLayout,
    component: componentLoader(() => import('src/views/jobs/create/JobCreatePage')),
  },
  {
    exact: true,
    path: '/jobs/:jobId/edit',
    guard: EmployerOrSalesGuard,
    layout: MainLayout,
    component: componentLoader(() => import('src/views/jobs/create/JobEditPage')),
  },
  {
    exact: true,
    path: '/jobs/:jobId/apply',
    layout: MainLayout,
    component: componentLoader(() => import('src/views/jobs/apply/JobApplyPage')),
  },
  {
    exact: true,
    path: '/404',
    component: componentLoader(() => import('src/views/errors/NotFoundView')),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/403',
    component: componentLoader(() => import('src/views/errors/ForbiddenView')),
  },
  {
    path: '*',
    routes: [
      {
        // eslint-disable-next-line react/display-name
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
