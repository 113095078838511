import type { FC, ReactNode } from 'react';
import React from 'react';
import { Redirect } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { isEmployer, isSales } from '../types/user';

interface EmployerOrSalesGuardProps {
  children?: ReactNode;
}

const EmployerOrSalesGuard: FC<EmployerOrSalesGuardProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/signin" />;
  }

  if (!isEmployer(user) && !isSales(user)) {
    return <Redirect to="/403" />;
  }

  return <>{children}</>;
};

export default EmployerOrSalesGuard;
