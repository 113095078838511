import type { FC, ReactNode } from 'react';
import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Collapse, Divider, ListItem, makeStyles } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import type { Theme } from 'src/theme';
import { FormattedMessage } from 'react-intl';

interface NavItemProps {
  children?: ReactNode;
  depth: number;
  href?: string;
  open?: boolean;
  title: string;
  bottomDivider?: boolean;
  onMobileClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    height: 55,
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    height: 55,
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.primary.main,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  buttonLeaf: {
    color: theme.palette.primary.main,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  title: {
    color: theme.palette.primary.main,
    marginRight: 'auto',
    paddingLeft: 8,
  },
  active: {
    // No specific CSS for selected menu item
  },
}));

const NavItem: FC<NavItemProps> = ({ children, depth, href, open: openProp = false, title, bottomDivider, onMobileClose }) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(openProp);

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;
  if (depth > 0) {
    paddingLeft = 32;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <>
        <ListItem className={classes.item} disableGutters key={title}>
          <Button className={classes.button} onClick={handleToggle} style={style}>
            <span className={classes.title}>
              <FormattedMessage id={title} />
            </span>
            {open ? <ArrowDropUp /> : <ArrowDropDown />}
          </Button>
        </ListItem>
        <Collapse in={open}>{children}</Collapse>
        {bottomDivider === true && <Divider variant="middle" style={{ marginTop: 10, marginBottom: 10 }} />}
      </>
    );
  }
  return (
    <>
      <ListItem className={classes.itemLeaf} disableGutters key={title} onClick={onMobileClose}>
        <Button activeClassName={classes.active} className={classes.buttonLeaf} component={RouterLink} exact style={style} to={href!}>
          <span className={classes.title}>
            <FormattedMessage id={title} />
          </span>
        </Button>
      </ListItem>
      {bottomDivider === true && <Divider variant="middle" style={{ marginTop: 10, marginBottom: 10 }} />}
    </>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  bottomDivider: PropTypes.bool,
  onMobileClose: PropTypes.func.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
