import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box, Button, Link, makeStyles, TextField, Typography } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Check, X } from 'react-feather';
import { FormikErrors, FormikHandlers, FormikTouched } from 'formik';
import { ShopCodeStep } from '../../../constants/states';
import { getRoleByShopCode } from '../../../lib/userService';
import { Role } from '../../../types/employment';
import { Theme } from '../../../theme';
import { OxyPlaceInfo } from '../../../types/user';

const useStyles = makeStyles((theme: Theme) => ({
  linkNoAnimation: {
    position: 'relative',
    textDecoration: 'none',
    cursor: 'pointer',
    fontFamily: 'Ubuntu',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.25,
    color: theme.palette.secondary.dark,
    '&:hover': {
      color: theme.palette.secondary.dark,
      textDecoration: 'none',
    },
    '&::after': {
      content: `''`,
      position: 'absolute',
      width: '100%',
      height: '1.2px',
      top: 18,
      bottom: 0,
      left: 0,
      backgroundColor: theme.palette.secondary.dark,
      visibility: 'visible',
    },
  },
  rectangle: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2),
    borderRadius: 8,
  },
}));

interface OxyCodeFieldProps extends Pick<FormikHandlers, 'handleBlur' | 'handleChange'> {
  forOxyPlaceInfo?: OxyPlaceInfo;
  shopCode: string;
  errors: FormikErrors<OxyPlaceInfo> | string;
  touched: FormikTouched<OxyPlaceInfo> | string;
  userRemovedOxyPlace: () => void;
  userSetValidOxyPlace: (validOxyPlace: OxyPlaceInfo) => void;
}

export const SHOP_CODE_LENGTH = 8;

const OxyCodeField: FC<OxyCodeFieldProps> = ({
  shopCode,
  forOxyPlaceInfo,
  handleBlur,
  handleChange,
  errors,
  touched,
  userRemovedOxyPlace,
  userSetValidOxyPlace,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [shopCodeStep, setShopCodeStep] = useState<ShopCodeStep>(ShopCodeStep.INIT);
  const [oxyPlaceInfo, setOxyPlaceInfo] = useState<OxyPlaceInfo>(forOxyPlaceInfo);
  const [readyToDeleteOxyPlace, setReadyToDeleteOxyPlace] = useState(false);
  const [roleName, setRoleName] = useState<string>(null);

  const handleShopCodeEntered = useCallback(
    (shopCodeToCheck: string) => {
      setShopCodeStep(ShopCodeStep.CHECKING);
      getRoleByShopCode(shopCodeToCheck).then((shopAndRole) => {
        const { shop, role } = shopAndRole;

        if (role === null) {
          setShopCodeStep(ShopCodeStep.NOT_FOUND);
        } else if (role === Role.EMPLOYEE) {
          const validOxyPlaceInfo = {
            shopCode: shopCodeToCheck,
            shopId: shop.id,
            shopName: shop.name,
            shopCodeStep: ShopCodeStep.SUCCESS_EMPLOYEE,
          } as OxyPlaceInfo;
          setOxyPlaceInfo(validOxyPlaceInfo);
          setRoleName(intl.formatMessage({ id: 'profile.form.shopCode.employee' }));
          setShopCodeStep(ShopCodeStep.SUCCESS_EMPLOYEE);
          userSetValidOxyPlace(validOxyPlaceInfo);
        } else if (role === Role.EMPLOYER) {
          const validOxyPlaceInfo = {
            shopCode: shopCodeToCheck,
            shopId: shop.id,
            shopName: shop.name,
            shopCodeStep: ShopCodeStep.SUCCESS_EMPLOYER,
          } as OxyPlaceInfo;
          setOxyPlaceInfo(validOxyPlaceInfo);
          setRoleName(intl.formatMessage({ id: 'profile.form.shopCode.employer' }));
          setShopCodeStep(ShopCodeStep.SUCCESS_EMPLOYER);
          userSetValidOxyPlace(validOxyPlaceInfo);
        }
      });
    },
    [intl, userSetValidOxyPlace],
  );

  // TODO there's some re-drawing here when user adds a new code. Needs some investigating
  useEffect(() => {
    if (shopCode.length === SHOP_CODE_LENGTH) {
      if (shopCode !== oxyPlaceInfo?.shopCode) handleShopCodeEntered(shopCode);
    } else if (shopCode.length === 0) {
      setShopCodeStep(ShopCodeStep.INIT);
    } else {
      setShopCodeStep(ShopCodeStep.HAS_ERROR);
    }
  }, [handleShopCodeEntered, shopCode, oxyPlaceInfo]);

  return (
    <Box>
      <TextField
        fullWidth
        error={Boolean(touched && errors)}
        helperText={touched && errors}
        label={intl.formatMessage({ id: 'profile.form.shopCode.optional' })}
        name="shopCode"
        type="text"
        onBlur={handleBlur}
        onChange={handleChange}
        value={shopCode}
        variant="outlined"
        color="secondary"
        margin="normal"
        size="small"
        disabled={
          shopCodeStep === ShopCodeStep.SUCCESS_EMPLOYER ||
          shopCodeStep === ShopCodeStep.SUCCESS_EMPLOYEE ||
          shopCodeStep === ShopCodeStep.CHECKING
        }
      />
      {(() => {
        switch (shopCodeStep) {
          case ShopCodeStep.NOT_FOUND:
            return (
              <Box ml="3px" display="flex" alignItems="center">
                <X strokeWidth="3px" size="20px" style={{ color: '#B00020', marginRight: 5 }} />
                <Typography color="primary" variant="caption">
                  <FormattedMessage id="profile.form.shopCode.error" />
                </Typography>
              </Box>
            );
          case ShopCodeStep.SUCCESS_EMPLOYEE:
          case ShopCodeStep.SUCCESS_EMPLOYER:
            return (
              <>
                <Box ml="3px" display="flex" alignItems="center">
                  <Check strokeWidth="3px" size="20px" style={{ color: '#28B446', marginRight: 5 }} />
                  <Typography color="primary" variant="caption">
                    <FormattedMessage
                      id="profile.form.shopCode.success"
                      values={{ role: <b>{roleName}</b>, shop: <b>{oxyPlaceInfo.shopName}</b> }}
                    />
                  </Typography>
                </Box>
                {!readyToDeleteOxyPlace && (
                  <Box display="flex" alignItems="center" mt={1.5}>
                    <Link className={classes.linkNoAnimation} onClick={() => setReadyToDeleteOxyPlace(true)}>
                      <FormattedMessage id="profile.form.shopCode.leave" values={{ shop: oxyPlaceInfo.shopName }} />
                    </Link>
                  </Box>
                )}
                {readyToDeleteOxyPlace && (
                  <Box mt={3} className={classes.rectangle}>
                    <Typography color="secondary" variant="caption">
                      <b>
                        <FormattedMessage id="profile.form.shopCode.leave.confirmation.title" />
                      </b>
                    </Typography>
                    <br />
                    <Typography color="secondary" variant="caption">
                      <FormattedMessage id="profile.form.shopCode.leave.confirmation.text" values={{ shop: oxyPlaceInfo.shopName }} />
                    </Typography>
                    <Box mt={3}>
                      <Button variant="contained" color="primary" size="small" onClick={userRemovedOxyPlace}>
                        <Typography color="inherit" variant="button">
                          <FormattedMessage id="profile.form.shopCode.leave.confirmation.cta" />
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                )}
              </>
            );
          default:
            return <></>;
        }
      })()}
    </Box>
  );
};

export default OxyCodeField;
