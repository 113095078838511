import type { FC } from 'react';
import React from 'react';
import type { Theme } from 'src/theme';
import { Box, Container, Grid, Link, makeStyles, Tooltip, Typography } from '@material-ui/core';
import Logo from 'src/components/Logo';
import { FormattedMessage } from 'react-intl';
import { Instagram, LinkedIn, Twitter } from '@material-ui/icons';
import FooterSupport from './FooterSupport';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6),
      paddingLeft: theme.spacing(2),
    },
  },
  link: {
    position: 'relative',
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.background.default,
    '&:hover': {
      color: theme.palette.background.default,
      textDecoration: 'none',
    },
    '&::after': {
      content: `''`,
      position: 'absolute',
      width: 0,
      height: 1,
      top: 20,
      bottom: 0,
      left: 0,
      backgroundColor: theme.palette.background.default,
      visibility: 'hidden',
      transition: '0.5s ease all .3s',
      [theme.breakpoints.down('sm')]: {
        transition: 'none',
      },
    },
    '&:hover::after': {
      width: '100%',
      visibility: 'visible',
      transition: '0.3s ease all',
      [theme.breakpoints.down('sm')]: {
        width: 0,
        visibility: 'hidden',
        transition: 'none',
      },
    },
  },
}));

const Footer: FC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Container disableGutters maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box mb={3}>
              <Box>
                <Logo color="outline" />
              </Box>
              <Box mt={1}>
                <Typography color="textSecondary" variant="h6">
                  <FormattedMessage id="footer.new.thank.you" />
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box>
              <Link href="/for-employees" className={classes.link}>
                <Typography color="textSecondary" variant="caption">
                  <FormattedMessage id="footer.for.employees" />
                </Typography>
              </Link>
            </Box>
            <Box mt={2}>
              <Link href="/for-employers" className={classes.link}>
                <Typography color="textSecondary" variant="caption">
                  <FormattedMessage id="footer.for.shops" />
                </Typography>
              </Link>
            </Box>
            <Box mt={2}>
              <Link href="/for-members" className={classes.link}>
                <Typography color="textSecondary" variant="caption">
                  <FormattedMessage id="footer.for.members" />
                </Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box>
              <Link href="/about" className={classes.link}>
                <Typography color="textSecondary" variant="caption">
                  <FormattedMessage id="footer.who.are.we" />
                </Typography>
              </Link>
            </Box>
            <Box mt={2}>
              <Link href="/places" className={classes.link}>
                <Typography color="textSecondary" variant="caption">
                  <FormattedMessage id="footer.partner.shops" />
                </Typography>
              </Link>
            </Box>
            <Box mt={2}>
              <Link href="/faq" className={classes.link}>
                <Typography color="textSecondary" variant="caption">
                  <FormattedMessage id="footer.faq" />
                </Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FooterSupport className={classes.link} />
            <Box mt={2}>
              <Link href="/terms" className={classes.link}>
                <Typography color="textSecondary" variant="caption">
                  <FormattedMessage id="footer.terms" />
                </Typography>
              </Link>
            </Box>
            <Box mt={2}>
              <Link href="/privacy-policy" className={classes.link}>
                <Typography color="textSecondary" variant="caption">
                  <FormattedMessage id="footer.privacy.policy" />
                </Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box mt={2}>
              <Typography color="textSecondary" variant="caption">
                © Oxy 2023
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box mt={2} maxWidth="120px" display="flex" justifyContent="space-between">
              <Link href="https://www.linkedin.com/company/oxy-community" target="_blank">
                <Tooltip title="LinkedIn" placement="bottom">
                  <LinkedIn style={{ color: '#CBCBCB' }} />
                </Tooltip>
              </Link>
              <Link href="https://twitter.com/OxyCommunity" target="_blank">
                <Tooltip title="Twitter" placement="bottom">
                  <Twitter style={{ color: '#CBCBCB' }} />
                </Tooltip>
              </Link>
              <Link href="https://instagram.com/oxy.community" target="_blank">
                <Tooltip title="Instagram" placement="bottom">
                  <Instagram style={{ color: '#CBCBCB' }} />
                </Tooltip>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
