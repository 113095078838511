import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Theme } from 'src/theme';
import { Check } from 'react-feather';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { AuthStep } from 'src/constants/states';
import { isFirstLogin } from 'src/types/user';
import useAuth from 'src/hooks/useAuth';

interface WelcomeProps {
  handleStepChange?: (authStep: AuthStep) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    flexGrow: 1,
    border: '1px solid #CBCBCB',
  },
  dividerText: {
    margin: theme.spacing(2),
    color: theme.palette.secondary.dark,
  },
  checkmark: {
    color: theme.palette.success.main,
    backgroundColor: theme.palette.success.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    padding: 6,
    width: 100,
    height: 100,
  },
}));

const Welcome: FC<WelcomeProps> = ({ handleStepChange }) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useAuth();

  const handleProfileClick = (): void => {
    if (handleStepChange) {
      handleStepChange(AuthStep.EDIT_PROFILE);
    } else {
      history.push({ pathname: '/profile', search: history.location.search });
    }
  };

  const handleGiveOxyClick = (): void => {
    if (handleStepChange) {
      handleStepChange(AuthStep.FINISH);
    }
  };

  return (
    <Box textAlign="center">
      <Box mt={6} display="flex" justifyContent="center">
        <Box className={classes.checkmark}>
          <Check strokeWidth="3px" size="60px" />
        </Box>
      </Box>
      {isFirstLogin(user) ? (
        <>
          <Box mt={4}>
            <Typography color="textPrimary" variant="h3">
              <FormattedMessage id="welcome.title" />
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography color="secondary" variant="body1">
              <FormattedMessage id="welcome.headline" />
            </Typography>
          </Box>
        </>
      ) : (
        <Box mt={4}>
          <Typography color="textPrimary" variant="h3">
            <FormattedMessage id="welcome.title.back" />
          </Typography>
        </Box>
      )}
      <Box mt={6}>
        <Button variant="contained" color="primary" fullWidth onClick={handleProfileClick}>
          <Typography color="textSecondary" variant="button">
            <FormattedMessage id="welcome.cta1" />
          </Typography>
        </Button>
      </Box>
      <Box mt={2} display="flex" alignItems="center">
        <Divider className={classes.divider} orientation="horizontal" />
        <Typography variant="caption" className={classes.dividerText}>
          <FormattedMessage id="welcome.or" />
        </Typography>
        <Divider className={classes.divider} orientation="horizontal" />
      </Box>
      <Box mt={2}>
        <Button variant="outlined" color="primary" fullWidth component={RouterLink} to="/places" onClick={handleGiveOxyClick}>
          <Typography color="inherit" variant="button">
            <FormattedMessage id="welcome.cta2" />
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

Welcome.propTypes = {
  handleStepChange: PropTypes.func,
};

export default Welcome;
