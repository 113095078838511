import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Link, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

interface FooterSupportProps {
  className: string;
}

const FooterSupport: FC<FooterSupportProps> = ({ className }) => {
  return (
    <Box>
      <Link href="/support" className={className}>
        <Typography color="textSecondary" variant="caption">
          <FormattedMessage id="footer.contact" />
        </Typography>
      </Link>
    </Box>
  );
};

FooterSupport.propTypes = {
  className: PropTypes.string.isRequired,
};

export default FooterSupport;
