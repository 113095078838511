import ReactPixel from 'react-facebook-pixel';

const facebookPixelId = '580529156981543';
const facebookPixelEnabled = process.env.REACT_APP_FACEBOOK_PIXEL_ENABLED === 'true';

const initializeFacebookPixel = () => {
  if (facebookPixelEnabled) {
    ReactPixel.init(facebookPixelId);
    ReactPixel.pageView(); // For tracking page view
  }
};

export default initializeFacebookPixel;
