import { FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { EntityWithAvatar, FirestoreUser, HasMultipleShopIds, OxyUser } from '../user';
import { Shop, ShopIdAndNameOnly } from '../shop';
import { OxyAggregate, OxyTotals } from '../oxy';
import { OxyJobWithPaymentStatus } from '../oxyJob';

const genericConverter = <T extends { id: string }>() =>
  ({
    toFirestore() {
      return {};
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): T {
      const data = snapshot.data(options)!;
      return {
        id: snapshot.id,
        ...data,
      } as T;
    },
  } as FirestoreDataConverter<T>);

const genericConverterNoId = <T>() =>
  ({
    toFirestore() {
      return {};
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): T {
      const data = snapshot.data(options)!;
      return {
        ...data,
      } as T;
    },
  } as FirestoreDataConverter<T>);

export const oxyUserFirebaseConverter = genericConverter<OxyUser>();
export const oxyFirestoreUserFirebaseConverter = genericConverter<FirestoreUser>();
export const shopFirebaseConverter = genericConverter<Shop>();
export const oxyJobFirebaseConverter = genericConverter<OxyJobWithPaymentStatus>();

export const entityWithAvatarFirebaseConverter = genericConverterNoId<EntityWithAvatar>();
export const oxyTotalsFirebaseConverter = genericConverterNoId<OxyTotals>();
export const oxyAggregateFirebaseConverter = genericConverterNoId<OxyAggregate>();
export const shopIdAndNameOnlyFirebaseConverter = genericConverterNoId<ShopIdAndNameOnly>();
export const hasMultipleShopIdsFirebaseConverter = genericConverterNoId<HasMultipleShopIds>();
