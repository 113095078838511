import React from 'react';
import type { FC, ReactNode } from 'react';
import { CheckCircle, Info, PriorityHigh, Warning } from '@material-ui/icons';
import { SnackbarProvider, SnackbarProviderProps } from 'notistack';
import { Theme } from 'src/theme';
import { makeStyles } from '@material-ui/core';

interface MySnackbarProviderProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginRight: theme.spacing(1),
    opacity: 0.9,
  },
  error: {
    borderRadius: 8,
    backgroundColor: `${theme.palette.error.main}!important`,
  },
  info: {
    borderRadius: 8,
    backgroundColor: `${theme.palette.info.main}!important`,
  },
  success: {
    borderRadius: 8,
    backgroundColor: `${theme.palette.success.main}!important`,
  },
  warning: {
    borderRadius: 8,
    backgroundColor: `${theme.palette.warning.main}!important`,
  },
}));

const MySnackbarProvider: FC<MySnackbarProviderProps> = ({ children }) => {
  const classes = useStyles();

  const snackbarClasses: SnackbarProviderProps['classes'] = {
    variantError: classes.error,
    variantInfo: classes.info,
    variantSuccess: classes.success,
    variantWarning: classes.warning,
  };

  const snackbarIcons: SnackbarProviderProps['iconVariant'] = {
    error: <PriorityHigh className={classes.icon} fontSize="small" />,
    info: <Info className={classes.icon} fontSize="small" />,
    success: <CheckCircle className={classes.icon} fontSize="small" />,
    warning: <Warning className={classes.icon} fontSize="small" />,
  };

  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={4000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={snackbarClasses}
      iconVariant={snackbarIcons}
    >
      {children}
    </SnackbarProvider>
  );
};

export default MySnackbarProvider;
