import type { FC, ReactNode } from 'react';
import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { AdminRole } from 'src/types/user';

interface SalesPersonGuardProps {
  children?: ReactNode;
}

const SalesPersonGuard: FC<SalesPersonGuardProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/signin" />;
  }
  if (user.adminRole !== AdminRole.SALES) {
    return <Redirect to="/403" />;
  }

  return <>{children}</>;
};

SalesPersonGuard.propTypes = {
  children: PropTypes.node,
};

export default SalesPersonGuard;
