import React, { ChangeEvent, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, makeStyles, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { AuthStep } from 'src/constants/states';
import SignInForm from './SignInView/SignInForm';
import SignUpForm from './SignUpView/SignUpForm';

interface SignOnTabsProps {
  authStep: AuthStep;
  handleStepChange?: (authStep: AuthStep) => void;
  emailInput?: string | null;
  openForgotPassword?: boolean;
}

const useStyles = makeStyles(() => ({
  tabs: {
    borderBottom: '1px solid #CBCBCB',
  },
}));

const SignOnTabs: FC<SignOnTabsProps> = ({ authStep, handleStepChange, emailInput, openForgotPassword }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const intl = useIntl();

  const tabs = [
    { value: AuthStep.SIGNIN, label: intl.formatMessage({ id: 'auth.signin' }) },
    { value: AuthStep.SIGNUP, label: intl.formatMessage({ id: 'auth.signup' }) },
  ];

  const handleChange = (event: ChangeEvent<unknown>, value: string): void => {
    if (handleStepChange) {
      handleStepChange(AuthStep[value]);
    } else {
      history.push({ pathname: value === AuthStep.SIGNUP ? '/signup' : '/signin', search: history.location.search });
    }
  };

  return (
    <>
      <Box textAlign="center">
        <Typography color="textPrimary" variant="h3">
          <FormattedMessage id="auth.signon" />
        </Typography>
      </Box>
      <Box mt={2}>
        <Tabs
          className={classes.tabs}
          indicatorColor="primary"
          onChange={handleChange}
          value={authStep}
          centered={!isMobile}
          variant="fullWidth"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
      </Box>
      <Box mt={3}>
        {authStep === AuthStep.SIGNIN && (
          <SignInForm handleStepChange={handleStepChange} emailInput={emailInput} openForgotPassword={openForgotPassword} />
        )}
        {authStep === AuthStep.SIGNUP && <SignUpForm handleStepChange={handleStepChange} emailInput={emailInput} />}
      </Box>
    </>
  );
};

SignOnTabs.propTypes = {
  authStep: PropTypes.oneOf(Object.values(AuthStep)).isRequired,
  handleStepChange: PropTypes.func,
  emailInput: PropTypes.string,
  openForgotPassword: PropTypes.bool,
};

export default SignOnTabs;
