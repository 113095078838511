// https://material-ui.com/customization/breakpoints
import { createTheme, responsiveFontSizes, Theme as BaseTheme } from '@material-ui/core';
import type { Palette as MuiPalette, TypeBackground as MuiTypeBackground } from '@material-ui/core/styles/createPalette';
import typography from 'src/theme/typography';

interface TypeBackground extends MuiTypeBackground {
  default: string;
  paper: string;
}

interface CustomColours extends MuiTypeBackground {
  grey5: string; // Is this the place for a color
  grey6: string; // Is this the place for a color
  grey7: string; // Is this the place for a color
}

interface Palette extends MuiPalette {
  background: TypeBackground;
  customColours: CustomColours;
}

export interface Theme extends BaseTheme {
  name: string;
  palette: Palette;
}

interface ThemeOptions {
  typography?: Record<string, unknown>;
  breakpoints?: Record<string, unknown>;
  overrides?: Record<string, unknown>;
  palette?: Record<string, unknown>;
  props?: Record<string, unknown>;
}

const baseOptions: ThemeOptions = {
  typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1092,
      xl: 1920,
    },
  },
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiRadio: {
      root: {
        color: '#707070',
      },
      colorSecondary: {
        '&$checked': {
          color: '#215E6C',
        },
      },
    },
    MuiButton: {
      root: {
        height: 48,
        padding: '12px 24px',
        borderRadius: 8,
        cursor: 'pointer',
      },
      sizeSmall: {
        height: 38,
        padding: '7px 16px',
      },
      containedPrimary: {
        boxShadow: 'none',
        backgroundColor: '#215E6C',
        '&:hover': {
          backgroundColor: '#164752',
        },
        '&:active': {
          backgroundColor: '#12333A',
        },
      },
      contained: {
        '&.$Mui-disabled': {
          color: '#FFFFFF',
          backgroundColor: 'rgba(33, 94, 108, 0.3)',
        },
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
      outlinedPrimary: {
        boxShadow: 'none',
        color: '#215E6C',
        border: '1px solid #215E6C',
        backgroundColor: '#FFFFFF',
        '&:hover': {
          backgroundColor: '#E7ECEB',
          border: '1px solid #215E6C',
        },
        '&:active': {
          backgroundColor: '#FFFFFF',
          border: '1px solid #215E6C',
        },
      },
      outlinedSecondary: {
        boxShadow: 'none',
        border: '1px solid #FFFFFF',
        '&:hover': {
          border: '1px solid #000000',
        },
        '&:active': {
          border: '1px solid #000000',
        },
      },
    },
    MuiMenu: {
      list: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiListItem: {
      root: {
        height: 44,
      },
    },
    MuiFormLabel: {
      root: {
        '@media (max-width:600px)': {
          lineHeight: 1,
        },
      },
      colorSecondary: {
        color: '#9E9E9E',
        fontFamily: 'Ubuntu',
        fontSize: 16,
        fontWeight: 400,
      },
    },
    MuiOutlinedInput: {
      input: {
        color: '#363636',
        fontFamily: 'Ubuntu',
        fontSize: 16,
        fontWeight: 400,
        backgroundColor: '#FFFFFF',
        borderRadius: 8,
        '&.$Mui-disabled': {
          opacity: 0.5,
        },
      },
      adornedStart: {
        backgroundColor: '#FFFFFF',
        borderRadius: 8,
      },
      multiline: {
        padding: 0,
      },
      inputMultiline: {
        padding: '18px 14px',
      },
    },
    MuiFormHelperText: {
      root: {
        color: 'inherit',
      },
      contained: {
        marginTop: 4,
        marginLeft: 4,
      },
    },
    MuiInputBase: {
      inputTypeSearch: {
        fontSize: 16,
        fontWeight: 400,
        fontFamily: 'Ubuntu',
        color: '#707070',
      },
    },
    MuiInput: {
      underline: {
        '&:hover:before': {
          borderBottom: '1px solid #363636',
        },
      },
    },
    PrivateNotchedOutline: {
      root: {
        borderRadius: 8,
        border: '1px solid #9E9E9E',
      },
    },
    MuiCircularProgress: {
      root: {
        position: 'absolute',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 16,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontFamily: 'Ubuntu',
        fontSize: 14,
        fontWeight: 400,
        borderRadius: 8,
        padding: 7,
      },
    },
    MuiSwitch: {
      colorPrimary: {
        '&.$Mui-checked': {
          color: '#215E6C',
          '& + $track': {
            opacity: 0.6,
            backgroundColor: '#215E6C',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#363636',
      dark: '#215E6C',
      light: '#E7ECEB',
    },
    secondary: {
      main: '#555555',
      dark: '#707070',
      light: '#9E9E9E',
    },
    error: {
      main: '#B00020',
      light: 'rgba(176, 0, 32, 0.15)',
    },
    warning: {
      main: '#FE842B',
      light: 'rgba(254, 132, 43, 0.15)',
    },
    info: {
      main: '#5295EF',
      light: 'rgba(82, 149, 239, 0.15)',
    },
    success: {
      main: '#28B446',
      light: 'rgba(40, 180, 70, 0.15)',
    },

    background: {
      default: '#FFFFFF',
      paper: '#CBCBCB',
    },
    customColours: {
      grey5: '#CBCBCB',
      grey6: '#E8E8E8',
      grey7: '#F6F6F6',
    },
    text: {
      primary: '#000000',
      secondary: '#FFFFFF',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
};

export const createOxyTheme = (): Theme => {
  let theme = createTheme(baseOptions);
  theme = responsiveFontSizes(theme);
  return theme as Theme;
};
