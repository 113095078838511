import type { FC, ReactNode } from 'react';
import React, { createContext, useEffect, useReducer } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import { createUser, getAllShopsForUser, getUser } from 'src/lib/userService';
import { AUTH_PROVIDERS, OxyUser, ShopInfoAndRole, UserUpdateResponse } from 'src/types/user';
import PropTypes from 'prop-types';
import { deleteAllCookies } from 'src/lib/utils';
import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
  getAdditionalUserInfo,
  GoogleAuthProvider,
  linkWithCredential,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  verifyPasswordResetCode,
} from 'firebase/auth';
import { oxyWebsiteAuth } from '../lib/oxyFirebase';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  isAnonymous: boolean;
  user: OxyUser | null;
}

export interface AuthContextValue extends AuthState {
  method: 'FirebaseAuth';
  createUserWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  sendPasswordResetEmail: (email: string) => Promise<void>;
  verifyPasswordResetCode: (code: string) => Promise<any>;
  confirmPasswordReset: (code: string, newPassword: string) => Promise<void>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  signInWithCustomToken: (token: string) => Promise<any>;
  signInWithGoogle: () => Promise<any>;
  signInWithFacebook: () => Promise<any>;
  signInAnonymously: () => Promise<any>;
  updateProfile: (userUpdateResponse: UserUpdateResponse) => Promise<any>;
  updateAvatar: (url?: string) => void;
  updateMyPlace: () => Promise<void>;
  signOut: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type AuthStateChangedAction = {
  type: 'AUTH_STATE_CHANGED';
  payload: {
    isAuthenticated: boolean;
    isAnonymous: boolean;
    user: OxyUser | null;
  };
};

type AvatarUpdatedAction = {
  type: 'AVATAR_UPDATED';
  payload: {
    avatar: string | null;
  };
};

type ProfileUpdatedAction = {
  type: 'PROFILE_UPDATED';
  payload: {
    userUpdateResponse: UserUpdateResponse | null;
    rawShopCodesForUser: string[];
    shopInfoAndRoleMap: Map<string, ShopInfoAndRole>;
  };
};

type MyPlaceUpdatedAction = {
  type: 'MY_PLACE_UPDATED';
  payload: {
    rawShopCodesForUser: string[];
    shopInfoAndRoleMap: Map<string, ShopInfoAndRole>;
  };
};

type Action = AuthStateChangedAction | AvatarUpdatedAction | ProfileUpdatedAction | MyPlaceUpdatedAction;

const initialAuthState: AuthState = {
  isInitialised: false,
  isAuthenticated: false,
  isAnonymous: false,
  user: null,
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, isAnonymous, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isAnonymous,
        isInitialised: true,
        user,
      };
    }
    case 'AVATAR_UPDATED': {
      const { avatar } = action.payload;

      const newUser: OxyUser = {
        ...state.user,
        avatar,
      };

      return {
        ...state,
        user: newUser,
      };
    }
    case 'PROFILE_UPDATED': {
      const { userUpdateResponse, rawShopCodesForUser, shopInfoAndRoleMap } = action.payload;

      const newUser: OxyUser = {
        ...state.user,
        name: userUpdateResponse.name,
        firstname: userUpdateResponse.firstname,
        lastname: userUpdateResponse.lastname,
        shopCodes: rawShopCodesForUser,
        shopIdsWithShopInfoAndRole: shopInfoAndRoleMap,
      };
      return {
        ...state,
        user: newUser,
      };
    }
    case 'MY_PLACE_UPDATED': {
      const { rawShopCodesForUser, shopInfoAndRoleMap } = action.payload;

      const newUser: OxyUser = {
        ...state.user,
        shopCodes: rawShopCodesForUser,
        shopIdsWithShopInfoAndRole: shopInfoAndRoleMap,
      };
      return {
        ...state,
        user: newUser,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  sendPasswordResetEmail: () => Promise.resolve(),
  verifyPasswordResetCode: () => Promise.resolve(),
  confirmPasswordReset: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithCustomToken: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  signInWithFacebook: () => Promise.resolve(),
  signInAnonymously: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
  updateAvatar: () => Promise.resolve(),
  updateMyPlace: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const createUserWithEmailAndPasswordOxy = async (email: string, password: string): Promise<any> => {
    return createUserWithEmailAndPassword(oxyWebsiteAuth, email, password).then((userCredential) => {
      const details = getAdditionalUserInfo(userCredential);
      if (details.isNewUser) {
        createUser(userCredential.user);
      }
    });
  };

  const sendPasswordResetEmailOxy = (email: string): Promise<void> => {
    return sendPasswordResetEmail(oxyWebsiteAuth, email);
  };

  const verifyPasswordResetCodeOxy = (code: string): Promise<any> => {
    return verifyPasswordResetCode(oxyWebsiteAuth, code);
  };

  const confirmPasswordResetOxy = (code: string, newPassword: string): Promise<void> => {
    return confirmPasswordReset(oxyWebsiteAuth, code, newPassword);
  };

  const signInWithEmailAndPasswordOxy = (email: string, password: string): Promise<any> => {
    return signInWithEmailAndPassword(oxyWebsiteAuth, email, password).catch(() => {
      indexedDB.deleteDatabase('firebaseLocalStorage');
      deleteAllCookies();
      return signInWithEmailAndPassword(oxyWebsiteAuth, email, password);
    });
  };

  const signInWithCustomTokenOxy = async (token: string): Promise<any> => {
    return signInWithCustomToken(oxyWebsiteAuth, token);
  };

  const signInWithGoogle = async (): Promise<any> => {
    const provider = new GoogleAuthProvider();
    return signInWithProvider(provider);
  };

  const signInWithFacebook = async (): Promise<any> => {
    const provider = new FacebookAuthProvider();
    return signInWithProvider(provider);
  };

  const signInWithProvider = async (provider: GoogleAuthProvider | FacebookAuthProvider): Promise<any> => {
    return signInWithPopup(oxyWebsiteAuth, provider)
      .then((userCredential) => {
        const { isNewUser } = getAdditionalUserInfo(userCredential);
        if (isNewUser) {
          createUser(userCredential.user);
          return {
            isNewUser: true,
            email: userCredential.user.email,
          };
        }
        return {
          isNewUser: false,
          email: userCredential.user.email,
        };
      })
      .catch((error) => {
        // case where existing user signs in with different auth provider
        if (error.code === 'auth/account-exists-with-different-credential') {
          fetchSignInMethodsForEmail(oxyWebsiteAuth, error.email).then((methods) => {
            const provider = AUTH_PROVIDERS[methods[0]];
            return signInWithPopup(oxyWebsiteAuth, provider).then((userCredential) => {
              linkWithCredential(userCredential.user, error.credential);
              return {
                isNewUser: false,
                email: error.email,
              };
            });
          });
        } else throw error;
      });
  };

  // TODO this will be needed to link anonymous users to real credentials
  // const linkAnonymousWithGoogle = (): Promise<any> => {
  //   const provider = new firebase.auth.GoogleAuthProvider();
  //   return firebase.auth().currentUser?.linkWithPopup(provider) ?? Promise.reject(new Error('User is not logged in'));
  // };

  const signInAnonymouslyOxy = async (): Promise<any> => {
    return signInAnonymously(oxyWebsiteAuth).then((userCredential) => {
      const { isNewUser } = getAdditionalUserInfo(userCredential);
      if (isNewUser) {
        createUser(userCredential.user);
        return {
          isNewUser: true,
          uid: userCredential.user.uid,
        };
      }
      return {
        isNewUser: false,
        uid: userCredential.user.uid,
      };
    });
  };

  const updateProfile = async (userUpdateResponse: UserUpdateResponse): Promise<any> => {
    return getAllShopsForUser(state.user.id).then(({ rawShopCodesForUser, shopInfoAndRoleMap }) => {
      dispatch({ type: 'PROFILE_UPDATED', payload: { userUpdateResponse, rawShopCodesForUser, shopInfoAndRoleMap } });
      return { shopIdsWithShopInfoAndRole: shopInfoAndRoleMap };
    });
  };

  const updateMyPlace = async (): Promise<void> => {
    return getAllShopsForUser(state.user.id).then(({ rawShopCodesForUser, shopInfoAndRoleMap }) => {
      return dispatch({ type: 'MY_PLACE_UPDATED', payload: { rawShopCodesForUser, shopInfoAndRoleMap } });
    });
  };

  const updateAvatar = (avatar?: string) => {
    dispatch({ type: 'AVATAR_UPDATED', payload: { avatar } });
  };

  const signOutOxy = (): Promise<void> => {
    return signOut(oxyWebsiteAuth);
  };

  useEffect(() => {
    return oxyWebsiteAuth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        // extract the complete user profile to make it available in your entire app
        getUser(firebaseUser).then((user) => {
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: true,
              isAnonymous: user.isAnonymous,
              user,
            },
          });
        });
      } else {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            isAnonymous: false,
            user: null,
          },
        });
      }
    });
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword: createUserWithEmailAndPasswordOxy,
        sendPasswordResetEmail: sendPasswordResetEmailOxy,
        verifyPasswordResetCode: verifyPasswordResetCodeOxy,
        confirmPasswordReset: confirmPasswordResetOxy,
        signInWithEmailAndPassword: signInWithEmailAndPasswordOxy,
        signInWithCustomToken: signInWithCustomTokenOxy,
        signInWithGoogle,
        signInWithFacebook,
        signInAnonymously: signInAnonymouslyOxy,
        updateProfile,
        updateAvatar,
        updateMyPlace,
        signOut: signOutOxy,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthContext;
