import { v4 } from 'uuid';
import { getDownloadURL, ref, uploadBytes, deleteObject } from 'firebase/storage';
import { oxyWebsiteStorage } from './oxyFirebase';

interface UploadedFile {
  name: string;
  url: string;
}

export const removeFileFromStorage = async (url: string): Promise<boolean> => {
  try {
    const fileRef = ref(oxyWebsiteStorage, url);
    await deleteObject(fileRef);
    return true;
  } catch (err) {
    if (err?.code === 'storage/invalid-url') {
      // @see https://firebase.google.com/docs/storage/web/handle-errors
      // Invalid URL provided to refFromURL(), eg. google account URL
      return false;
    }
    throw err;
  }
};

export const uploadFileToStorage = async (file: File, path: string): Promise<UploadedFile> => {
  const filename = `${v4()}.${file.name.split('.').pop()}`;
  const fileRef = ref(ref(oxyWebsiteStorage, path), filename);
  const uploadResult = await uploadBytes(fileRef, file);

  const url = await getDownloadURL(uploadResult.ref);
  return {
    name: filename,
    url: url as string,
  };
};

const delay = (ms: number): Promise<any> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// @see https://stackoverflow.com/questions/58977241/how-to-get-the-resized-downloadurl-after-upload-with-firebase-storage-web-sdk/58978012#58978012
const retry = async (fileRef, attempts = 10, interval = 500): Promise<string> => {
  return getDownloadURL(fileRef)
    .then((url) => {
      return url;
    })
    .catch((error) => {
      if (attempts === 0) {
        return Promise.reject(error);
      }
      switch (error.code) {
        case 'storage/object-not-found':
          return delay(interval).then(() => {
            return retry(fileRef, attempts - 1, interval);
          });
        default:
          return Promise.reject(error);
      }
    });
};

// @see https://stackoverflow.com/questions/64183467/how-to-get-the-new-download-url-after-firebase-extensions-resize-images-with-ang
export const getResizedURL = async (filename: string, path: string, dimensions = '180x180'): Promise<string> => {
  const extIndex = filename.lastIndexOf('.');
  const ext = filename.substring(extIndex);
  const filenameResized = `${filename.substring(0, extIndex)}_${dimensions}${ext}`;
  const fileRef = ref(ref(oxyWebsiteStorage, path), filenameResized);
  return retry(fileRef);
};
