import { FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { Employment, FirestoreProfilePrivacySettings, Role } from './employment';
import { ShopInfo } from './shop';
import { ShopCodeStep } from '../constants/states';

export interface OxyPlaceInfo {
  shopCode: string;
  shopId: string;
  shopName: string;
  shopCodeStep: ShopCodeStep;
}

export interface OxyUser {
  id: string;
  email?: string;
  adminRole?: AdminRole;
  name?: string;
  avatar?: string;
  firstname?: string;
  lastname?: string;
  shopIdsWithShopInfoAndRole?: Map<string, ShopInfoAndRole>;
  shopCodes?: string[];
  creationTime?: string;
  lastSignInTime?: string;
  isAnonymous: boolean;
  isNewUser: boolean;
}

export interface ShopInfoAndRole extends ShopInfo {
  role: Role;
}

export interface FirestoreUser extends HasMultipleShopIds {
  id: string;
  email: string;
  adminRole?: AdminRole;
  name: string;
  avatar: string;
  firstname: string;
  lastname: string;
  employmentHistoryByShopId: Employment;
  creationTime?: Timestamp;
  lastSignInTime?: Timestamp;
  isNewUser: boolean;
}

export interface HasMultipleShopIds {
  shopIds: Map<string, Map<string, unknown> & FirestoreProfilePrivacySettings>;
}

export interface UserUpdateRequest {
  firstname: string;
  lastname: string;
  mapOfShopCodes: Map<string, OxyPlaceInfo>;
}

export interface UserUpdateResponse {
  name: string;
  firstname: string;
  lastname: string;
  isNewUser: boolean;
}

export interface ProfileUpdateResponse {
  shopIdsWithShopInfoAndRole?: Map<string, ShopInfoAndRole>;
}

export enum AdminRole {
  NONE = 'NONE',
  SALES = 'SALES',
}

export const AUTH_PROVIDERS = {
  'google.com': new GoogleAuthProvider(),
  'facebook.com': new FacebookAuthProvider(),
};

export enum ContactType {
  EMPLOYEE = 'employee',
  EMPLOYER = 'employer',
}

export interface ContactFormRequest {
  email: string;
  contactType: string;
  shopName: string;
  shopArea: string;
  name: string;
  phone?: string;
  message?: string;
}

export interface EntityWithAvatar {
  avatar: string;
}

export function isSales(user: OxyUser): boolean {
  return user?.adminRole === AdminRole.SALES;
}

export function isEmployer(user: OxyUser): boolean {
  return Array.from(user.shopIdsWithShopInfoAndRole.values()).some((eachRole) => eachRole.role === Role.EMPLOYER);
}

export function isEmployerByShopInfo(profileUpdateResponse: ProfileUpdateResponse): boolean {
  return Array.from(profileUpdateResponse.shopIdsWithShopInfoAndRole.values()).some((eachRole) => eachRole.role === Role.EMPLOYER);
}

export function isEmployerForShop(user: OxyUser, shopId: string): boolean {
  return Array.from(user.shopIdsWithShopInfoAndRole).some((eachRole) => eachRole[0] === shopId && eachRole[1].role === Role.EMPLOYER);
}

export function getPublicName(user: OxyUser | FirestoreUser): string {
  if (user.lastname) {
    return `${user.firstname} ${user.lastname.substring(0, 1)}.`;
  }
  if (isNameTypeOfEmail(user)) {
    return user.email?.substring(0, 1).toUpperCase();
  }
  if (user.name.split(' ').length > 1) {
    return `${user.name.split(' ')[0]} ${user.name.split(' ')[1].substring(0, 1)}.`;
  }
  return user.name;
}

export function getInitials(user: OxyUser): string {
  if (user.lastname) {
    return `${user.firstname.substring(0, 1).toUpperCase()}${user.lastname.substring(0, 1).toUpperCase()}`;
  }
  if (isNameTypeOfEmail(user)) {
    return user.email?.substring(0, 1).toUpperCase();
  }
  if (user.name.split(' ').length > 1) {
    return `${user.name.split(' ')[0].substring(0, 1).toUpperCase()}${user.name.split(' ')[1].substring(0, 1).toUpperCase()}`;
  }
  return user.name?.substring(0, 1).toUpperCase();
}

export function isFirstLogin(user: OxyUser): boolean {
  return user?.creationTime && user.creationTime === user.lastSignInTime;
}

export function isNameTypeOfEmail(user: OxyUser | FirestoreUser): boolean {
  return user.name === user.email;
}

export function userMatches(user: FirestoreUser, shopQuery: string): boolean {
  return getPublicName(user).toLowerCase().includes(shopQuery);
}
