export enum Locale {
  EL = 'el',
  EN = 'en',
}

require('dayjs/locale/el');

interface LocaleItem {
  value: Locale;
  name: string;
  text: string;
}

export const LOCALES: { [key: string]: LocaleItem } = {
  el: {
    value: Locale.EL,
    name: 'GR',
    text: 'GR - Ελληνικά',
  },
  en: {
    value: Locale.EN,
    name: 'EN',
    text: 'EN - English',
  },
};
