// https://firebase.google.com/docs/web/modular-upgrade

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import firebaseConfig from 'src/config';

export const oxyWebsiteFirebaseApp = initializeApp(firebaseConfig);
export const oxyWebsiteAnalytics = getAnalytics(oxyWebsiteFirebaseApp); // Just initialize analytics
export const oxyWebsiteFirestore = getFirestore(oxyWebsiteFirebaseApp);
export const oxyWebsiteAuth = getAuth(oxyWebsiteFirebaseApp);
export const oxyWebsiteStorage = getStorage(oxyWebsiteFirebaseApp);
