import { ContactFormRequest, HasMultipleShopIds, ShopInfoAndRole, OxyUser, UserUpdateRequest, UserUpdateResponse } from 'src/types/user';
import { getFullAddressWithSpace, Shop } from 'src/types/shop';
import { Locale } from 'src/constants/locales';
import { FormikValues } from 'formik';
import { ENVIRONMENT, EnvType, WEBSITE_URL } from 'src/constants';
import { User } from 'firebase/auth';
import {
  doc,
  setDoc,
  Timestamp,
  getDoc,
  getDocs,
  query,
  where,
  collection,
  documentId,
  limit,
  writeBatch,
  deleteField,
  serverTimestamp,
  updateDoc,
  addDoc,
} from 'firebase/firestore';
import { InstructionsFor } from '../views/places/PlaceView/PlaceOnboardForm';
import { firestoreProfilePrivacySettingsTypeInfo, firestoreShopTypeInfo, firestoreUserTypeInfo } from './nameOfTypes';
import { EmploymentEvent, EmploymentEventType, FirestoreProfilePrivacySettings, Role } from '../types/employment';
import { convertObjectToMap } from './utils';
import { ShopCodeStep } from '../constants/states';
import { oxyWebsiteFirestore } from './oxyFirebase';
import { oxyFirestoreUserFirebaseConverter, shopFirebaseConverter } from '../types/firebaseConverters/genericConverter';

export const createUser = async (firebaseUser: User): Promise<OxyUser> => {
  const initialRole = Role.MEMBER;
  const name = firebaseUser.displayName ?? firebaseUser.email;
  const isNewUser = true;
  const isProfilePublic = true;
  const isOxyPublic = true;
  const isOxyPublicToEmployer = true;
  const { isAnonymous } = firebaseUser;

  await setDoc(doc(oxyWebsiteFirestore, 'users', firebaseUser.uid), {
    email: firebaseUser.email,
    role: initialRole,
    name,
    avatar: firebaseUser.photoURL,
    isAnonymous,
    isNewUser,
    creationTime: Timestamp.fromMillis(Date.parse(firebaseUser.metadata.creationTime)),
    lastSignInTime: Timestamp.fromMillis(Date.parse(firebaseUser.metadata.lastSignInTime)),
    isProfilePublic,
    isOxyPublic,
    isOxyPublicToEmployer,
  });

  return {
    id: firebaseUser.uid,
    email: firebaseUser.email,
    // We skip adminRole intentionally here - we want that to be added only from the Cloud Firestore website
    name,
    avatar: firebaseUser.photoURL,
    firstname: null,
    lastname: null,
    creationTime: firebaseUser.metadata.creationTime,
    lastSignInTime: firebaseUser.metadata.lastSignInTime,
    isAnonymous,
    isNewUser,
  };
};

export async function getAllShopsForUser(firebaseUserId: string) {
  const firestoreUserDocument = doc(oxyWebsiteFirestore, 'users', firebaseUserId).withConverter(oxyFirestoreUserFirebaseConverter);

  const firestoreUser = await getDoc(firestoreUserDocument).then((doc) => doc.data());

  const pureMap = convertObjectToMap<FirestoreProfilePrivacySettings>(firestoreUser.shopIds);

  const rawShopCodesForUser: string[] = [];
  const rolesMap = new Map<string, Role>();
  const shopInfoAndRoleMap = new Map<string, ShopInfoAndRole>();
  Array.from(pureMap).forEach(([innerKey, innerValue]) => {
    rolesMap.set(innerKey, innerValue.role as Role);
  });

  if (rolesMap.size > 0) {
    const shopIds = Array.from(rolesMap.keys());

    const firestoreShopDocuments = await getDocs(
      query(collection(oxyWebsiteFirestore, 'shops').withConverter(shopFirebaseConverter), where(documentId(), 'in', shopIds)),
    );

    firestoreShopDocuments.docs.forEach((eachDocument) => {
      const data = eachDocument.data() as Shop;
      data.id = eachDocument.id;
      const role = rolesMap.get(eachDocument.id);
      const shopInfoAndRole: ShopInfoAndRole = {
        role,
        url: data.url ?? data.id,
        ...data,
      };
      shopInfoAndRoleMap.set(eachDocument.id, shopInfoAndRole);

      // Add the codes straight from the shop the user is in
      if (role === Role.EMPLOYER) rawShopCodesForUser.push(data.employerCode);
      else if (role === Role.EMPLOYEE) rawShopCodesForUser.push(data.employeeCode);
    });
  }
  return { firestoreUserDocument, firestoreUser, rawShopCodesForUser, shopInfoAndRoleMap };
}

export const getUser = async (firebaseUser: User): Promise<OxyUser> => {
  const { firestoreUserDocument, firestoreUser, rawShopCodesForUser, shopInfoAndRoleMap } = await getAllShopsForUser(firebaseUser.uid);

  return {
    id: firestoreUserDocument.id,
    email: firestoreUser.email,
    adminRole: firestoreUser.adminRole,
    name: firestoreUser.name,
    shopIdsWithShopInfoAndRole: shopInfoAndRoleMap,
    shopCodes: rawShopCodesForUser,
    avatar: firestoreUser.avatar,
    firstname: firestoreUser.firstname,
    lastname: firestoreUser.lastname,
    creationTime: firebaseUser.metadata.creationTime,
    lastSignInTime: firebaseUser.metadata.lastSignInTime,
    isAnonymous: firebaseUser.isAnonymous,
    isNewUser: firestoreUser.isNewUser,
  };
};

export const getRoleByShopCode = async (shopCode: string): Promise<{ shop?: Shop; role?: Role }> => {
  return getDocs(
    query(
      collection(oxyWebsiteFirestore, 'shops').withConverter(shopFirebaseConverter),
      where(firestoreShopTypeInfo('employeeCode'), '==', shopCode),
      limit(1),
    ),
  ).then((shopDataAsEmployee) => {
    if (!shopDataAsEmployee.empty) {
      return {
        shop: { ...shopDataAsEmployee.docs[0].data(), id: shopDataAsEmployee.docs[0].id } as Shop,
        role: Role.EMPLOYEE,
      };
    }
    return getDocs(
      query(
        collection(oxyWebsiteFirestore, 'shops').withConverter(shopFirebaseConverter),
        where(firestoreShopTypeInfo('employerCode'), '==', shopCode),
        limit(1),
      ),
    ).then((shopDataAsEmployer) => {
      if (!shopDataAsEmployer.empty) {
        return {
          shop: { ...shopDataAsEmployer.docs[0].data(), id: shopDataAsEmployer.docs[0].id } as Shop,
          role: Role.EMPLOYER,
        };
      }
      return { shop: null, role: null };
    });
  });
};

export const updateUser = async (
  uid: string,
  shopCodesSoFar: string[],
  userUpdateRequest: UserUpdateRequest,
): Promise<UserUpdateResponse> => {
  const name = `${userUpdateRequest.firstname} ${userUpdateRequest.lastname}`;
  const isNewUser = false;

  const validShopCodesFromUserUpdateRequest: string[] = [];
  userUpdateRequest.mapOfShopCodes.forEach((eachShopCode) => {
    if (eachShopCode.shopCodeStep === ShopCodeStep.SUCCESS_EMPLOYER || eachShopCode.shopCodeStep === ShopCodeStep.SUCCESS_EMPLOYEE) {
      validShopCodesFromUserUpdateRequest.push(eachShopCode.shopCode);
    }
  });

  // Calculate shops user is adding
  const shopCodesAdded = validShopCodesFromUserUpdateRequest.filter((x) => !shopCodesSoFar.includes(x));
  const shopIdsUserIsAdding: { shopId: string; role: Role }[] = [];

  shopCodesAdded.forEach((eachShopCodeAdded) => {
    const shopId = userUpdateRequest.mapOfShopCodes.get(eachShopCodeAdded)?.shopId;
    const shopStep = userUpdateRequest.mapOfShopCodes.get(eachShopCodeAdded)?.shopCodeStep;
    if (shopId)
      shopIdsUserIsAdding.push({
        shopId,
        role:
          shopStep === ShopCodeStep.SUCCESS_EMPLOYEE
            ? Role.EMPLOYEE
            : shopStep === ShopCodeStep.SUCCESS_EMPLOYER
            ? Role.EMPLOYER
            : Role.MEMBER,
      });
  });

  // Calculate shops user is leaving
  const shopIdsUserIsLeaving: { shopId: string; role: Role }[] = [];
  const shopCodesRemoved = shopCodesSoFar.filter((x) => !validShopCodesFromUserUpdateRequest.includes(x));

  const allPromises = new Array<Promise<void>>();
  shopCodesRemoved.forEach((eachShopCodeRemoved) => {
    allPromises.push(
      getRoleByShopCode(eachShopCodeRemoved).then(({ shop, role }) => {
        shopIdsUserIsLeaving.push({ shopId: shop.id, role });
      }),
    );
  });

  await Promise.all(allPromises);

  // Set up write batch
  const batch = writeBatch(oxyWebsiteFirestore);

  const userDoc = doc(oxyWebsiteFirestore, 'users', uid);
  const documentSnapshot = await getDoc(userDoc);

  const shopIdsForUserFromUserData = documentSnapshot.data() as HasMultipleShopIds;
  const userMapOfShopIds: Map<string, FirestoreProfilePrivacySettings> = convertObjectToMap<FirestoreProfilePrivacySettings>(
    shopIdsForUserFromUserData.shopIds,
  );

  const updateData = [];
  // Remove shops user has left
  shopIdsUserIsLeaving.forEach((shopLeaving) => {
    userMapOfShopIds.delete(shopLeaving.shopId);
    updateData[`${firestoreUserTypeInfo('shopIds')}.${shopLeaving.shopId}`] = deleteField();
  });

  // Add shops user has joined
  shopIdsUserIsAdding.forEach((eachShopIdAdded) => {
    userMapOfShopIds.set(eachShopIdAdded.shopId, {
      // Default privacy settings when adding a new shop
      isOxyPublic: true,
      isOxyPublicToEmployer: true,
      isProfilePublic: true,
      role: eachShopIdAdded.role,
    } as FirestoreProfilePrivacySettings);
  });

  // Convert to firebase writable format
  userMapOfShopIds.forEach((privacySettingsForShop: FirestoreProfilePrivacySettings, shopId) => {
    Object.entries(privacySettingsForShop).forEach(([innerKey, innerValue]) => {
      updateData[`${firestoreUserTypeInfo('shopIds')}.${shopId}.${innerKey}`] = innerValue;
    });
  });

  // Change name and last name and first name
  batch.set(
    userDoc,
    {
      [firestoreUserTypeInfo('name')]: name,
      [firestoreUserTypeInfo('firstname')]: userUpdateRequest.firstname,
      [firestoreUserTypeInfo('lastname')]: userUpdateRequest.lastname,
      [firestoreUserTypeInfo('isNewUser')]: isNewUser,
    },
    { merge: true },
  );

  // Change the shop ids hash map
  batch.update(userDoc, { ...updateData });

  // Handle employment collection
  const firebaseServerTimestamp = serverTimestamp();
  shopIdsUserIsLeaving.forEach(({ shopId: eachShopIdUserIsLeaving, role }) => {
    batch.set(doc(collection(userDoc, firestoreUserTypeInfo('employmentHistoryByShopId'), eachShopIdUserIsLeaving, 'employmentEvents')), {
      eventType: EmploymentEventType.LEFT,
      createdAt: firebaseServerTimestamp,
      role,
    } as EmploymentEvent);
  });
  shopIdsUserIsAdding.forEach(({ shopId: eachShopIdUserIsAdding, role }) => {
    batch.set(doc(collection(userDoc, firestoreUserTypeInfo('employmentHistoryByShopId'), eachShopIdUserIsAdding, 'employmentEvents')), {
      eventType: EmploymentEventType.JOINED,
      createdAt: firebaseServerTimestamp,
      role,
    } as EmploymentEvent);
  });

  return batch.commit().then(
    () =>
      ({
        name,
        firstname: userUpdateRequest.firstname,
        lastname: userUpdateRequest.lastname,
        isNewUser,
      } as UserUpdateResponse),
  );
};

export const updateEntityAvatar = async (docIdPath: string, avatar?: string): Promise<void> => {
  return updateDoc(doc(oxyWebsiteFirestore, docIdPath), {
    avatar,
  });
};

export const updateUserPrivacyFlags = async (
  uid: string,
  shopId: string,
  privacySettings: {
    isProfilePublic?: boolean;
    isOxyPublic?: boolean;
    isOxyPublicToEmployer?: boolean;
  },
): Promise<void> => {
  const updateData = [];

  const isProfilePublicEntry = `${firestoreUserTypeInfo('shopIds')}.${shopId}.${firestoreProfilePrivacySettingsTypeInfo(
    'isProfilePublic',
  )}`;
  const isOxyPublicEntry = `${firestoreUserTypeInfo('shopIds')}.${shopId}.${firestoreProfilePrivacySettingsTypeInfo('isOxyPublic')}`;
  const isOxyPublicToEmployerEntry = `${firestoreUserTypeInfo('shopIds')}.${shopId}.${firestoreProfilePrivacySettingsTypeInfo(
    'isOxyPublicToEmployer',
  )}`;

  if (privacySettings.isProfilePublic !== undefined && privacySettings.isProfilePublic !== null)
    updateData[isProfilePublicEntry] = privacySettings.isProfilePublic;

  if (privacySettings.isOxyPublic !== undefined && privacySettings.isOxyPublic !== null)
    updateData[isOxyPublicEntry] = privacySettings.isOxyPublic;

  if (privacySettings.isOxyPublicToEmployer !== undefined && privacySettings.isOxyPublicToEmployer !== null)
    updateData[isOxyPublicToEmployerEntry] = privacySettings.isOxyPublicToEmployer;

  if (Object.keys(updateData).length > 0) {
    return updateDoc(doc(oxyWebsiteFirestore, 'users', uid), {
      ...updateData,
    });
  }
  return Promise.resolve();
};

export const triggerSupportEmail = async (locale: Locale, values: FormikValues): Promise<any> => {
  return addDoc(collection(oxyWebsiteFirestore, 'emails'), {
    to: ENVIRONMENT === EnvType.PRODUCTION ? values.email : 'hello@oxy.community',
    bcc: 'hello@oxy.community',
    template: {
      name: `support-${locale}`,
      data: {
        websiteUrl: WEBSITE_URL,
        email: values.email,
        message: values.message,
        name: values.name,
        phone: values.phone,
      },
    },
  });
};

export const triggerContactEmail = async (locale: Locale, request: ContactFormRequest): Promise<any> => {
  return addDoc(collection(oxyWebsiteFirestore, 'emails'), {
    to: ENVIRONMENT === EnvType.PRODUCTION ? request.email : 'hello@oxy.community',
    bcc: 'hello@oxy.community',
    template: {
      name: `contact-${locale}`,
      data: {
        websiteUrl: WEBSITE_URL,
        email: request.email,
        contactType: request.contactType,
        shopName: request.shopName,
        shopArea: request.shopArea,
        name: request.name,
        phone: request.phone,
        message: request.message,
      },
    },
  });
};

export const triggerEmployerLeadEmail = async (locale: Locale, request: ContactFormRequest): Promise<any> => {
  return addDoc(collection(oxyWebsiteFirestore, 'emails'), {
    to: ENVIRONMENT === EnvType.PRODUCTION ? request.email : 'hello@oxy.community',
    bcc: 'hello@oxy.community',
    template: {
      name: `employerLead-${locale}`,
      data: {
        websiteUrl: WEBSITE_URL,
        email: request.email,
        contactType: request.contactType,
        shopName: request.shopName,
        shopArea: request.shopArea,
        name: request.name,
        phone: request.phone,
        message: request.message,
      },
    },
  });
};

export const triggerSignupEmail = async (locale: Locale, email: string): Promise<any> => {
  return addDoc(collection(oxyWebsiteFirestore, 'emails'), {
    to: ENVIRONMENT === EnvType.PRODUCTION ? email : 'hello@oxy.community',
    bcc: 'hello@oxy.community',
    template: {
      name: `signup-${locale}`,
      data: {
        websiteUrl: WEBSITE_URL,
        email,
      },
    },
  });
};

export const triggerAnonymousEmail = async (locale: Locale, uid: string, email: string): Promise<any> => {
  return addDoc(collection(oxyWebsiteFirestore, 'emails'), {
    to: ENVIRONMENT === EnvType.PRODUCTION ? email : 'hello@oxy.community',
    bcc: 'hello@oxy.community',
    template: {
      name: `anonymous-${locale}`,
      data: {
        websiteUrl: WEBSITE_URL,
        uid,
        email,
      },
    },
  });
};

export const triggerOnboardEmail = async (locale: Locale, email: string, shop: Shop, instructionsFor: InstructionsFor): Promise<any> => {
  return addDoc(collection(oxyWebsiteFirestore, 'emails'), {
    to: ENVIRONMENT === EnvType.PRODUCTION ? email : 'hello@oxy.community',
    bcc: 'hello@oxy.community',
    template: {
      name: instructionsFor === 'forEmployer' ? `onboard-owner-${locale}` : `onboard-employee-${locale}`,
      data: {
        websiteUrl: WEBSITE_URL,
        email,
        placeId: shop.url ?? shop.id,
        placeName: shop.name,
        placeAddress: getFullAddressWithSpace(shop),
        employeeCode: shop.employeeCode,
        employerCode: shop.employerCode,
      },
    },
  });
};
