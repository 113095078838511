import type { FC } from 'react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, IconButton, List, makeStyles } from '@material-ui/core';
import { Theme } from 'src/theme';
import { Close } from '@material-ui/icons';
import NavItem from './NavItem';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  title: string;
  href?: string;
  items?: Item[];
  bottomDivider?: boolean;
}

const items: Item[] = [
  {
    title: 'menu.partner.shops',
    href: '/places',
  },
  {
    title: 'menu.partner.jobs',
    href: '/jobs',
    bottomDivider: true,
  },
  {
    title: 'menu.for.shops',
    href: '/for-employers',
  },
  {
    title: 'menu.for.employees',
    href: '/for-employees',
  },
  {
    title: 'menu.for.members',
    href: '/for-members',
    bottomDivider: true,
  },
  {
    title: 'menu.about.oxy',
    items: [
      {
        title: 'menu.who.are.we',
        href: '/about',
      },
      {
        title: 'menu.about.faq',
        href: '/faq',
      },
    ],
  },
  {
    title: 'menu.contact',
    href: '/support',
  },
];

function renderNavItems({
  items,
  pathname,
  depth = 0,
  onMobileClose,
}: {
  items: Item[];
  pathname: string;
  depth?: number;
  onMobileClose: () => void;
}) {
  return (
    // eslint-disable-next-line
    <List component="nav" disablePadding>
      {items.reduce<Item[]>((acc, item) => reduceChildRoutes({ acc, item, pathname, depth, onMobileClose }), [])}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  item,
  pathname,
  depth = 0,
  onMobileClose,
}: {
  acc: any[];
  item: Item;
  pathname: string;
  depth: number;
  onMobileClose: () => void;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = item.items.some((i) => i.href === pathname);
    acc.push(
      <NavItem
        depth={depth}
        key={key}
        open={Boolean(open)}
        title={item.title}
        bottomDivider={item.bottomDivider}
        onMobileClose={onMobileClose}
      >
        {renderNavItems({
          items: item.items,
          pathname,
          depth: depth + 1,
          onMobileClose,
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        key={key}
        title={item.title}
        bottomDivider={item.bottomDivider}
        onMobileClose={onMobileClose}
      />,
    );
  }

  return acc;
}

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    width: 280,
    backgroundColor: theme.palette.background.default,
  },
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box mt={2}>
        <IconButton color="primary" size="medium" onClick={onMobileClose}>
          <Close />
        </IconButton>
      </Box>
      <Box>{renderNavItems({ items, pathname: location.pathname, onMobileClose })}</Box>
    </Box>
  );

  return (
    <Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
      {content}
    </Drawer>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func.isRequired,
  openMobile: PropTypes.bool.isRequired,
};

export default NavBar;
