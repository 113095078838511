import type { FC } from 'react';
import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  List,
  ListItem as MuiListItem,
  makeStyles,
  Popover,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Locale, LOCALES } from 'src/constants/locales';
import useLocale from 'src/hooks/useLocale';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Theme } from 'src/theme';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
  popover: {
    backgroundColor: theme.palette.background.default,
    width: 180,
    marginTop: 1,
    borderRadius: 8,
    border: '1px solid #CBCBCB',
    boxShadow: 'none',
  },
}));

const ListItem = withStyles({
  root: {
    '&$selected': {
      backgroundColor: 'transparent',
      color: '#000000',
    },
    '&$selected:hover': {
      backgroundColor: 'transparent',
      color: '#000000',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#000000',
    },
  },
  selected: {},
})(MuiListItem);

const LocaleSelector: FC = () => {
  const classes = useStyles();
  const context = useLocale();
  const { locale } = context;

  const [selectedValue, setSelectedValue] = useState(locale);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const newLocale = event.target.value as Locale;
    setSelectedValue(newLocale);
    context.updateLocale(newLocale);
    setAnchorEl(null);
  };

  return (
    <Box>
      <List component="nav" className={classes.root} disablePadding dense>
        <ListItem button onClick={handleClick}>
          <Typography color="primary" variant="button">
            {LOCALES[locale].name}
          </Typography>
          {open ? <ExpandLess color="inherit" /> : <ExpandMore color="inherit" />}
        </ListItem>
      </List>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClick={handleClose}
        onClose={handleClose}
        PaperProps={{ className: classes.popover }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box padding={2}>
          <Typography color="secondary" variant="caption">
            <FormattedMessage id="menu.language" />
          </Typography>
          <Box mt={1}>
            <FormControl size="small">
              <RadioGroup aria-label="locale" name="locale" value={selectedValue} onChange={handleChange}>
                {Object.entries(LOCALES).map(([key, item]) => (
                  <FormControlLabel
                    key={key}
                    value={item.value}
                    control={<Radio />}
                    label={
                      <Typography color="primary" variant="caption">
                        {item.text}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default LocaleSelector;
