import type { FC, ReactNode } from 'react';
import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { isEmployer } from '../types/user';

interface EmployerGuardProps {
  children?: ReactNode;
}

const EmployerGuard: FC<EmployerGuardProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/signin" />;
  }

  if (!isEmployer(user)) {
    return <Redirect to="/403" />;
  }

  return <>{children}</>;
};

EmployerGuard.propTypes = {
  children: PropTypes.node,
};

export default EmployerGuard;
