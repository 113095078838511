import type { FC, ReactNode } from 'react';
import React from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';

interface GuestOrAnonymousGuardProps {
  children?: ReactNode;
}

const GuestOrAnonymousGuard: FC<GuestOrAnonymousGuardProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  if (isAuthenticated && !user?.isAnonymous) {
    if (user.isNewUser) {
      return (
        <Redirect
          to={{
            pathname: '/welcome',
            search: history.location.search,
          }}
        />
      );
    }

    const email = query.get('email');

    if (email === user.email) {
      // If there is something in the url that is actually intended for the current user, then go to profile to set it up
      return (
        <Redirect
          to={{
            pathname: '/profile',
            search: history.location.search,
          }}
        />
      );
    }

    return <Redirect to="/dashboard" />;
  }

  return <>{children}</>;
};

GuestOrAnonymousGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestOrAnonymousGuard;
