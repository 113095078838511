import { Timestamp } from 'firebase/firestore';

export type ShopIdAndNameOnly = Pick<ShopInfo, 'name'>;

export interface Shop extends ShopInfo {
  id: string;
  creationTime: Timestamp;
  createdByUserId?: string;
  isPublic: boolean;
}

export interface ShopInfo {
  employeeCode: string;
  employerCode: string;
  name: string;
  nameInsensitive: string;
  area: string;
  address: string;
  avatar: string;
  url?: string;
  tags?: string[];
  hideTipMsg?: boolean;
}

export enum ShopTag {
  ALL = 'ALL',
  FOOD_BEVERAGE = 'FOOD_BEVERAGE',
  ENTERTAINMENT = 'ENTERTAINMENT',
  BEAUTY = 'BEAUTY',
  ACCOMMODATION = 'ACCOMMODATION',
}

export const shopTags: Map<ShopTag, string> = new Map([
  [ShopTag.ALL, 'places.tags.all'],
  [ShopTag.FOOD_BEVERAGE, 'places.tags.foodBeverage'],
  [ShopTag.ENTERTAINMENT, 'places.tags.entertainment'],
  [ShopTag.BEAUTY, 'places.tags.beauty'],
  [ShopTag.ACCOMMODATION, 'places.tags.accommodation'],
]);

export const shopTagsOptions = {
  FOOD_BEVERAGE: 'places.tags.foodBeverage',
  ENTERTAINMENT: 'places.tags.entertainment',
  BEAUTY: 'places.tags.beauty',
  ACCOMMODATION: 'places.tags.accommodation',
};

export function getFullNameAndAddress(shop: ShopInfo): string {
  return `${shop.name} \u2022 ${shop.area}, ${shop.address}`;
}

export function getFullAddress(shop: ShopInfo): string {
  return `${shop.area} \u2022 ${shop.address}`;
}

export function getFullAddressWithSpace(shop: ShopInfo): string {
  return `${shop.area} ${shop.address}`;
}

export function shopMatches(shop: ShopInfo, searchQuery: string): boolean {
  return (
    shop.name.toLowerCase().includes(searchQuery) ||
    shop.area.toLowerCase().includes(searchQuery) ||
    shop.address.toLowerCase().includes(searchQuery)
  );
}

export function shopTagMatches(shop: ShopInfo, tagQuery: ShopTag): boolean {
  return tagQuery === ShopTag.ALL || shop.tags?.includes(tagQuery);
}
