import type { FC } from 'react';
import React from 'react';
import LogoSolid from 'src/assets/img/logo-solid.svg';
import LogoSolidRed from 'src/assets/img/logo-solid-red.svg';
import LogoSolidMobile from 'src/assets/img/logo-solid-mobile.svg';
import LogoSolidMobileRed from 'src/assets/img/logo-solid-mobile-red.svg';
import LogoOutline from 'src/assets/img/logo-outline.svg';
import PropTypes from 'prop-types';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { ENVIRONMENT, EnvType } from 'src/constants';

interface LogoProps {
  color?: 'solid' | 'outline';
}

const Logo: FC<LogoProps> = ({ color = 'solid' }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (color === 'solid') {
    if (ENVIRONMENT === EnvType.STAGING) {
      return <img alt="Oxy" src={isMobile ? LogoSolidMobileRed : LogoSolidRed} />;
    }
    return <img alt="Oxy" src={isMobile ? LogoSolidMobile : LogoSolid} />;
  }
  return <img alt="Oxy" src={LogoOutline} />;
};

Logo.propTypes = {
  color: PropTypes.oneOf(['solid', 'outline']),
};

export default Logo;
