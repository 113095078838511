import React, { FC, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Container, IconButton, makeStyles } from '@material-ui/core';
import { Theme } from 'src/theme';
import { Close } from '@material-ui/icons';
import { AuthStep } from 'src/constants/states';
import SignOnTabs from 'src/views/auth/SignOnTabs';
import Welcome from 'src/views/auth/WelcomeView/Welcome';
import ProfileForm from 'src/views/profile/ProfileView/ProfileForm';
import ForgotPasswordForm from 'src/views/auth/SignInView/ForgotPasswordForm';
import ForgotPasswordSuccess from 'src/views/auth/SignInView/ForgotPasswordSuccess';

interface AuthStepsProps {
  authStep: AuthStep;
  onCloseDialog: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 530,
    paddingBottom: theme.spacing(6),
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      height: '150%',
    },
  },
  closeIcon: {
    width: '55px',
    height: '55px',
    top: '15px',
    right: '4px',
    position: 'absolute',
    cursor: 'pointer',
  },
}));

const AuthSteps: FC<AuthStepsProps> = ({ authStep, onCloseDialog }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState<AuthStep>(authStep);
  const [emailInput, setEmailInput] = useState<string | null | undefined>(null);

  const handleStepChange = (step: AuthStep, email?: string): void => {
    setActiveStep(step);
    setEmailInput(email);
  };

  return (
    <Container className={classes.container}>
      <Box mt={3}>
        <IconButton color="primary" size="medium" className={classes.closeIcon} onClick={onCloseDialog}>
          <Close />
        </IconButton>
        {(() => {
          switch (activeStep) {
            case AuthStep.SIGNIN:
              return <SignOnTabs authStep={AuthStep.SIGNIN} handleStepChange={handleStepChange} emailInput={emailInput} />;
            case AuthStep.SIGNUP:
              return <SignOnTabs authStep={AuthStep.SIGNUP} handleStepChange={handleStepChange} emailInput={emailInput} />;
            case AuthStep.FORGOT_PASSWORD:
              return <ForgotPasswordForm handleStepChange={handleStepChange} emailInput={emailInput} />;
            case AuthStep.FORGOT_PASSWORD_SUCCESS:
              return <ForgotPasswordSuccess handleStepChange={handleStepChange} emailInput={emailInput} />;
            case AuthStep.WELCOME:
              return <Welcome handleStepChange={handleStepChange} />;
            case AuthStep.EDIT_PROFILE:
              return <ProfileForm handleStepChange={handleStepChange} />;
            default:
              return onCloseDialog();
          }
        })()}
      </Box>
    </Container>
  );
};

AuthSteps.propTypes = {
  authStep: PropTypes.oneOf(Object.values(AuthStep)).isRequired,
  onCloseDialog: PropTypes.func.isRequired,
};

export default AuthSteps;
