import { Timestamp } from 'firebase/firestore';

export enum Role {
  MEMBER = 'MEMBER',
  EMPLOYEE = 'EMPLOYEE',
  EMPLOYER = 'EMPLOYER',
}

export enum EmploymentEventType {
  JOINED = 'JOINED',
  LEFT = 'LEFT',
}

export interface FirestoreProfileShopAccess {
  role: Role;
}

export interface FirestoreProfilePrivacySettings extends FirestoreProfileShopAccess {
  isOxyPublic: boolean;
  isOxyPublicToEmployer: boolean;
  isProfilePublic: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Employment {} // TODO some day we will read the employment data here

export interface EmploymentEvent {
  role: Role;
  eventType: EmploymentEventType;
  createdAt: Timestamp;
}

export interface EmploymentDates {
  startedAt: string;
  stoppedAt: string;
}
