import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { enableES5 } from 'immer';
import store from 'src/store';
import App from 'src/App';
import * as serviceWorker from 'src/serviceWorker';
import AppLocaleContext from 'src/contexts/AppLocaleContext';
import initializeSentry from './lib/sentry';
import initializeFacebookPixel from './lib/facebookPixel';
import 'src/extensions/dates';

enableES5();
initializeSentry();
initializeFacebookPixel();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppLocaleContext>
        <App />
      </AppLocaleContext>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
